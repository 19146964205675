import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
//import { Dropdown, Input } from 'semantic-ui-react';
import { Grid, List } from 'semantic-ui-react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import {Link} from "react-router-dom";

class InclusionForm extends Component {

  constructor(props) {
    super(props);
    /*
     * 3.1.0 29.06.2021 10:24 changing from array to array of dictionaries
     * to allow inclusion of active=true/false.
     * This also makes the includeSelect/excludeSelect unnecessary
     */
    let include = [];
    let exclude = [];
    // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
    // 3.1.0 28.06.2021 13:28 UPDATE: Now codes is map, so we take keys
    for (const [code, val] of Object.entries(this.props.codes)) { // Moved here from a method 3.1.0 18.06.2021 <13:57
      var codeObject = {content: code, active: false, key: code}; // At the beginning all the active are false
      if (val["isIncluded"]) { // 3.1.0 28.06.2021 14:54
        include.push(codeObject);
      }
      else {
        exclude.push(codeObject);
      }
    }
    this.state = {
      included: include,
      excluded: exclude,
    }
  }

  /*
  * Thanks Paul S on https://stackoverflow.com/questions/42060961/react-router-v4-link-for-form
  * 3.1.0 18.06.2021 14:13
  */
  handleChange = (name, event) => {
    const target = event.target;
    // console.log(target);
    //console.log(name);
    //console.log(target.innerText);
    // I know that for the dropdowns the info is in target.innerText, and for the input in target.value (works as of 18.06.2021 14:41)
    let value = "";
    //value = target.value;
    value = target.innerText; // Works for List.Item as well!
    //if (value.includes('\n')) {
    //console.log("We had a line break, taking first line ");
    //   value = value.substring(0, value.indexOf('\n'));
    // }
    // console.log(value);
    // 3.1.0 29.06.2021
    let include = this.state.included;
    let exclude = this.state.excluded;
    if (name === "include" || name === "exclude") {
      //if (name === "include") {
        /* Move from excluded to included ot vice versa.
         * To get the correct lists for from and to list, just inquire
         * about the name. For include fromList is exclude and
         * toList is include, and for exclude it's the other way
         * around
         */
        //let fromListName = name === "include" ? "excluded" : "included";
        let fromList = name === "include" ? exclude : include;
        //let toListName = name === "include" ? "included" : "excluded";
        let toList = name === "include" ? include : exclude;
        // Now take all the active options
        let activeOptions = fromList
        .filter(codeObject => {return codeObject.active});
        /* Now go over all active items, find their index in
         * the fromList to splice them out, toggle their active to
         * false, and then push them into toList
         */
        activeOptions.forEach(option => {
          let index = fromList.indexOf(option);
          fromList.splice(index, 1);
          option.active = !option.active;
          toList.push(option);
        });
      //}
      //else  {
        // Move from included to excluded
      //}
    }
    else  {
      // The actual arrays to change are actually the same included/excluded
      let properName = name === "includeSelect" ? "included" : "excluded";
      //if (name === "includeSelect") {
        //console.log(this.state.[properName]);
        /*
         * Find the correct item in the
         * included/excluded array and toggle
         * its active attribute
         */
        let option = this.state.[properName]
        .filter(codeObject => {return codeObject.content === value});
        option[0].active = !option[0].active;
        //console.log(option);
        // if (this.state.[name].includes(value)) {
        //   let index = this.state.[name].indexOf(value);
        //   this.state.[name].splice(index, 1);
        // }
        // else {
        //   this.state.[name].push(value);
        // }
      }
      //else  {
        // Add/remove item to/from the excludeSelect list
      //}
    this.setState((state) => {
      return {included: include,
        excluded: exclude,}
      });
    }

    /**
    * Send the data to the MatchingDashboardBackEndCommunicator
    * to be sent to the backend to handle.
    * If I ever decide to communicate directly with the
    * database for performance, I shall change the call here.
    * @implNote Adding an if else to check for a bad submit 3.1.0 21.06.2021 13:43
    * @since 3.0.0 29.04.2021 14:12
    */
    submitForm = () => {
      const {included} = this.state;
      let newCodes = this.props.codes;
      let shouldInclude = true;
      for (const [code, val] of Object.entries(this.props.codes)) { // 3.1.0 29.06.2021 09:20
        // console.log(code);
        //console.log(included);
        let option = included
        .filter(codeObject => {
          // console.log(codeObject);
          return codeObject.content === code});
        // console.log(option);
        if (option.length !== 0) {
          shouldInclude = true;
        }
        else {
          shouldInclude = false;
        }
        // newCodes.push(
        //   {[code]:{isIncluded:shouldInclude, isBinary: val["isBinary"]}}
        // );
        newCodes.[code].isIncluded=shouldInclude;
      }
      // console.log(newCodes);
      // (13:39) now it is submitBooleans and has a true for isInclusion at the end
      // 3.1.0 01.07.2021 11:00 now it is submitConfigs and has a isIncluded for type at the end
      MatchingDashboardBackEndCommunicator.submitConfigs(newCodes, this.props.matchingName, "isIncluded");
      this.props.updateCodes(newCodes, true);
    }

    /* Thanks Boky on https://stackoverflow.com/questions/40989524/how-to-get-id-and-input-data-when-onchange-event-is-happening-react-js
    * for the trick with the bind in order to only have one handleChange function (3.1.0 18.06.2021 <<14:32)
    */
    render() {
      //console.log("codes: " + this.props.codes);
      // console.log("included: " + this.state.included);
      // console.log("included[0] code: " + this.state.included[0].content);
      // console.log("included[0] active: " + this.state.included[0].active);
      // console.log("excluded: " + this.state.excluded);
      return(
        <Form className="ui centered">
          <div className="ui centered center-stage">
            <Grid>
              <Grid.Column key={0}>
                <h3>Inclusion list</h3>
                <List selection
                  onItemClick={this.handleChange.bind(this,"includeSelect")}
                  items={this.state.included} />
              </Grid.Column>
              <Grid.Column key={1}>
                <br/>
                <Button onClick={this.handleChange.bind(this,"exclude")}>
                  =>
                </Button>
                <Button onClick={this.handleChange.bind(this,"include")}>
                  &lt;=
                </Button>
              </Grid.Column>
              <Grid.Column key={2}>
                <h3>Exclusion list</h3>
                <List selection
                  onItemClick={this.handleChange.bind(this,"excludeSelect")}
                  items={this.state.excluded} />
              </Grid.Column>
            </Grid>
          </div>
          <div className="buttonGroup">
            <Link className="btn btn-warning" to="/dash/edit_meta" id="backButton">
              Back
            </Link>
            <Button type="submit" className="btn btn-success" id="submitButton"
              onClick={this.submitForm}>
              Submit
            </Button>
          </div>
        </Form>
        );
      }
    }

    export default InclusionForm;
