/**
* The Component to handle the score
* editing for a specific question
* @implNote 3.3.0 06.08.2021 16:02 in order to avoid doing a separate form
* to edit an existing meta (which would look ALMOST EXACTLY the same),
* I'm gonna do some minor changes.
* First, I'm gonna move a lot of the initial state setting to the props.
* That way, for a creation one, I can send in the same defaults as before
* as the props, but for editing, I can send the actual details.
* Next, I will add a new boolean called edit.
* For edit, changing the name is disabled, and the backend will handle
* it differently.
* @since 3.0.0 27.04.2021 ?~11??
*/
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
//import { Dropdown } from 'semantic-ui-react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import {Link} from "react-router-dom";

class CreateNewMatchingMetaForm extends Component {
  // 3.3.0 06.08.2021 ~16:25+?
  edit = false;
  constructor(props) {
    super(props);
    this.edit = this.props.edit; // 3.3.0 06.08.2021 16:21 determine if editing
    this.state = {
      /*
      * The name of the logged
      * in user.
      * It will be hardcoded until I
      * implement the auth/sso stuff
      * 3.0.0 27.04.2021 11:06
      */
      username: this.props.username,//"tech4comp",
      /**
      * This will hold the acceptable
      * matching meta names for the user
      * @since 3.0.0 27.04.2021 14:??
      */
      metaName: this.props.metaName,
      /**
      * This will hold the
      * current chosen meta type.
      * Default is REGULAR
      * @since 3.0.0 27.04.2021 14:??
      */
      metaType: this.props.metaType,
      /**
      * This will hold the
      * current maxGroupSize
      * @since 3.0.0 27.04.2021 14:43
      */
      maxGroupSize: this.props.maxGroupSize,
      /**
      * This will hold the
      * current mentorSurveyId
      * @since 3.0.0 27.04.2021 14:43
      */
      mentorSurveyId: this.props.mentorSurveyId,
      /**
      * This will hold the
      * current menteeSurveyId
      * @since 3.0.0 27.04.2021 14:43
      */
      menteeSurveyId: this.props.menteeSurveyId,
      /**
       * This will show a loader as long as the matching meta is not yet
       * saved in db
       * @since 3.2.0 28.07.2021 14:42
       */
      showLoader: false,
      /**
       * For the leader choice dropdown
       * @since 3.2.0 05.08.2021 ??:??
       */
      leaderChoiceMethod: this.props.leaderChoiceMethod,
      /**
       * For the matching method radio
       * @since 3.3.0 06.08.2021 14:33
       */
      matchingMethod: this.props.matchingMethod,
    }
    this.prepareForm = this.prepareForm.bind(this);
    //this.handleNameChange = this.handleNameChange.bind(this);
    this.triggerLoader = this.triggerLoader.bind(this);
  }

  /**
   * trigger the loader on and off
   * @since 3.2.0 28.07.2021 14:43
   */
  async triggerLoader(showLoader) {
    this.setState((state) => {
      return {
        showLoader: showLoader,
      }
    });
  }

  /**
  * Update name
  * @since 3.0.0 27.04.2021 14:42
  */
  handleNameChange = event => {
    this.setState((state) => {
    return {
      metaName: event.target.value,
    }});
  }

  /**
  * Update type
  * @implNote 3.3.0 06.08.2021 14:58 prefer to add conditions to make sure that
  * mentorSurveyId is -1 if it was 0
  * (15:05) in order to do it properly, I'll remove the outer if,
  * and resolve all things inside the setState lambda
  * @since 3.0.0 27.04.2021 14:40
  */
  handleTypeChange = (e, { value }) => {
    //if (value === 'REGULAR' || value === 'PEER') {
      this.setState((state) => {
      var mentorId = state.mentorSurveyId;
      if ((mentorId === 0 && value !== 'PEERGROUP')
          || value === 'PEER'
          || value === 'CANDIDATESELECTION'
          || (value === 'PEERGROUP' && state.leaderChoiceMethod === 'RANDOM')) {
        mentorId = -1;
      } else if (value === 'PEERGROUP' && state.leaderChoiceMethod === 'SAME') {
        mentorId = 0;
      }
      return {
        maxGroupSize: value === 'REGULAR' || value === 'PEER'
          ? 1 : state.maxGroupSize,
        metaType: value,
        mentorSurveyId: mentorId,
      }});
    // } else {
    //   this.setState((state) => {
    //   return {
    //     metaType: value,
    //   }});
    // }
  }

  /**
  * Update maxGroupSize
  * @since 3.0.0 27.04.2021 14:46
  */
  handleGroupSizeChange = event => {
    this.setState((state) => {
    return {
      maxGroupSize: parseInt(event.target.value),
    }});
  }

  /**
  * Update mentorSurveyId
  * @since 3.0.0 27.04.2021 14:56
  */
  handleMentorSurveyChange = event => {
    this.setState((state) => {
    return {
      mentorSurveyId: parseInt(event.target.value),
    }});
  }

  /**
  * Update menteeSurveyId
  * @since 3.0.0 27.04.2021 14:56
  */
  handleMenteeSurveyChange = event => {
    this.setState((state) => {
    return {
      menteeSurveyId: parseInt(event.target.value),
    }});
  }

  /**
  * Update Ledear selection method
  * @since 3.2.0 05.08.2021 12:28
  */
  handleLeaderSelectionChange = (e, { value }) => {
    if (value === 'RANDOM') {
      this.setState((state) => {
      return {
        mentorSurveyId: -1,
        leaderChoiceMethod: value,
      }});
    } else if (value === 'SAME') {
      this.setState((state) => {
      return {
        mentorSurveyId: 0,
        leaderChoiceMethod: value,
      }});
    } else {
      this.setState((state) => {
      return {
        leaderChoiceMethod: value,
      }});
    }
  }

  /**
  * Update Matchinge method
  * @since 3.3.0 06.08.2021 14:37
  */
  handleMethodChange = (e, { value }) => {
      this.setState((state) => {
      return {
        matchingMethod: value,
      }});
  }

  /**
    *
    * @since 2.1.0 15.12.2020 13:57
    */
  prepareForm() {
    const options = [
      {key:1, text:'Regular matching', value:'REGULAR'},
      {key:2, text:'Group matching', value:'GROUP'},
      {key:3, text:'Peer matching', value:'PEER'},
      {key:4, text:'Peer group matching', value:'PEERGROUP'},
      {key:5, text:'Candidate selection', value:'CANDIDATESELECTION'},
    ];
    // 3.2.0 05.08.2021 12:19
    const leaderChoiceOptions = [
      {key:'RANDOM', text:'Choosing the group leaders randomly', value:'RANDOM'},
      {key:'SAME', text:'Using the same survey for group leaders', value:'SAME'},
      {key:'SEPARATE', text:'Using a separate survey for group leaders', value:'SEPARATE'},
    ];
    const formFields = [];
//      for (var i = 1; i < currentQuestion.length; i++) {
      formFields.push(
        <Form.Field key={100000}>
          <label>Name</label>
          <input
            //placeholder='Score'
            defaultValue={this.state.metaName}
            disabled={this.edit}
            onChange={this.handleNameChange}
          />
        </Form.Field>
      );
      // 3.3.0 06.08.2021 14:11 - radio button for optimal vs. on demand
      formFields.push(
        <Form.Group inline key={100006}>
          <label>Matching method</label>
          <Form.Radio
            label='Optimal'
            value='OPTIMAL'
            checked={this.state.matchingMethod === 'OPTIMAL'}
            onChange={this.handleMethodChange}
          />
          <Form.Radio
            label='On demand'
            value='ON_DEMAND'
            checked={this.state.matchingMethod === 'ON_DEMAND'}
            onChange={this.handleMethodChange}
          />
        </Form.Group>
      );
      formFields.push(
      //<Dropdown key={100001}
      <Form.Select key={100001}
        onChange={this.handleTypeChange}
        options={options}
        placeholder='Choose a matching type'
        //selection
        value={this.state.metaType}
      />
      );
      if (this.state.metaType === 'REGULAR' || this.state.metaType === 'PEER') {
        formFields.push(
        <Form.Field key={100002}>
          <label>Max group size</label>
          <input
            //placeholder='Score'
            //defaultValue="1"
            value={this.state.maxGroupSize}
            disabled
          />
        </Form.Field>
        );
      } else {
        formFields.push(
        <Form.Field key={100002}>
          <label>Max group size</label>
          <input
            //placeholder='Score'
            //defaultValue=""
            value={this.state.maxGroupSize}
            onChange={this.handleGroupSizeChange}
          />
        </Form.Field>
      );
      }
      if (this.state.metaType === 'REGULAR' || this.state.metaType === 'GROUP') {
        formFields.push(
          <Form.Field key={100003}>
            <label>Mentor survey Id</label>
            <input
              //placeholder='Score'
              //defaultValue=""
              value={this.state.mentorSurveyId}
              onChange={this.handleMentorSurveyChange}
            />
          </Form.Field>
        );
        formFields.push(
          <Form.Field key={100004}>
            <label>Mentee survey Id</label>
            <input
              //placeholder='Score'
              //defaultValue=""
              value={this.state.menteeSurveyId}
              onChange={this.handleMenteeSurveyChange}
            />
          </Form.Field>
        );
      } else if (this.state.metaType === 'PEERGROUP') { // added 3.2.0 23.07.2021 15:14
        /*
         * 3.2.0 04.08.2021 11:42
         * Now it is time to implement what I've discussed with Guelru:
         * A dropdown that lets one choose between using the same
         * survey, using a separate one, and randomly choosing leaders
         */
        formFields.push(
          <Form.Select key={100005}
            onChange={this.handleLeaderSelectionChange}
            options={leaderChoiceOptions}
            //placeholder='Choose a matching'
            value={this.state.leaderChoiceMethod}
          />
        );
        var menteeSurveyLabel = '';
        if (this.state.leaderChoiceMethod === 'SEPARATE') {
          formFields.push(
            <Form.Field key={100003}>
              <label>Ledership survey Id</label>
              <input
                //defaultValue=""
                value={this.state.mentorSurveyId}
                onChange={this.handleMentorSurveyChange}
              />
            </Form.Field>
          );
          menteeSurveyLabel = 'General survey Id';
        } else {
          menteeSurveyLabel = 'Survey Id';
        }
        formFields.push(
          <Form.Field key={100004}>
            <label>{menteeSurveyLabel}</label>
            <input
              //placeholder='Score'
              //defaultValue=""
              value={this.state.menteeSurveyId}
              onChange={this.handleMenteeSurveyChange}
            />
          </Form.Field>
        );
      } else {
        formFields.push(
          <Form.Field key={100004}>
            <label>Survey Id</label>
            <input
              //placeholder='Score'
              //defaultValue=""
              value={this.state.menteeSurveyId}
              onChange={this.handleMenteeSurveyChange}
            />
          </Form.Field>
        );
      }

//      }
    return(formFields);
  }

  /**
   * Send the data to the MatchingDashboardBackEndCommunicator
   * to be sent to the backend to handle.
   * If I ever decide to communicate directly with the
   * database for performance, I shall change the call here.
   * @implNote 3.2.0 28.07.2021 14:40 in order to allow the loader, I need
   * it to be async. Then I will  do a triggerLoader, and put the togggleCreation
   * in a then()
   * @implNote 3.3.0 06.08.2021 14:37 added matchingMethod
   * (16:34) added this.edit
   * @since 3.0.0 29.04.2021 14:12
   */
  submitForm = async () => {
    const { username, metaName, metaType, maxGroupSize, mentorSurveyId, menteeSurveyId, matchingMethod } = this.state;
    await this.triggerLoader(true);
    await MatchingDashboardBackEndCommunicator.submitMatchingMeta(username, metaName, metaType, maxGroupSize, mentorSurveyId, menteeSurveyId, matchingMethod, this.edit)
    .then(response => {
      /*
       * 3.2.0 28.07.2021 15:35
       * If the response has error in it
       * then trigger modal
       */
       if (response.data.error !== undefined) {
         console.log("Want to trigger modal");
         this.props.triggerModal(true);
         this.props.toggleCreation();
       }
      else {
        this.props.toggleCreation(metaName);
      }
    }, (error) => { // This was also added for the modal 3.2.0 28.07.2021 15:16
      this.props.triggerModal(true);
    });
  }

  /**
  *
  * @implNote the back button Toggles the other state without saving info
  * @since 2.1.0 15.12.2020 13:37
  */
  render(){
    console.log('mentor survey id: ',this.state.mentorSurveyId);
    console.log('mentee survey id: ',this.state.menteeSurveyId);
    console.log('meta name is: ',this.state.metaName);
    console.log('meta type is: ',this.state.metaType);
    console.log('max group size: ',this.state.maxGroupSize);
    console.log('matchingMethod: ',this.state.matchingMethod);
    let formFields = this.prepareForm();
    return(
      <div>
        <Form >
          <h1 className="ui centered">Matching details:</h1>
          {formFields}
          {/*<Button onClick={() => this.props.toggleCreation()}>*/}
          <Link className="btn btn-warning" to="/" id="backButton">
            Cancel
          </Link>
          {/*</Button>*/}
          <Button type="submit" className="btn btn-success" id="submitButton"
            onClick={this.submitForm}>
            {/*Create matching*/}
            {this.edit ? "Edit matching" : "Create matching"}
          </Button>
        </Form>
        <Dimmer active={this.state.showLoader}>
          <Loader indeterminate>Saving new meta in database</Loader>
        </Dimmer>
      </div>
    )
  }

}

export default CreateNewMatchingMetaForm;
