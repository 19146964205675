import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
//import { Dropdown, Input } from 'semantic-ui-react';
import { Grid, List } from 'semantic-ui-react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import {Link} from "react-router-dom";

class CalculationForm extends Component {

  constructor(props) {
    super(props);
    /*
     * array of dictionaries
     * to allow inclusion of active=true/false.
     */
    let regular = [];
    let binary = [];
    // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
    // codes is map, so we take keys
    for (const [code, val] of Object.entries(this.props.codes)) {
      var codeObject = {content: code, active: false, key: code}; // At the beginning all the active are false
      if (!val["isBinary"]) {
        regular.push(codeObject);
      }
      else {
        binary.push(codeObject);
      }
    }
    this.state = {
      regular: regular,
      binary: binary,
    }
  }

  /*
  * Thanks Paul S on https://stackoverflow.com/questions/42060961/react-router-v4-link-for-form
  * 3.1.0 18.06.2021 14:13
  */
  handleChange = (name, event) => {
    const target = event.target;
    // I know that for the dropdowns the info is in target.innerText, and for the input in target.value (works as of 18.06.2021 14:41)
    let value = "";
    value = target.innerText; // Works for List.Item as well!
    // console.log(value);
    // 3.1.0 29.06.2021
    let regular = this.state.regular;
    let binary = this.state.binary;
    if (name === "regular" || name === "binary") {
        /* Move from binary to included ot vice versa.
         * To get the correct lists for from and to list, just inquire
         * about the name. For regular fromList is binary and
         * toList is regular, and for binary it's the other way
         * around
         */
        let fromList = name === "regular" ? binary : regular;
        let toList = name === "regular" ? regular : binary;
        // Now take all the active options
        let activeOptions = fromList
        .filter(codeObject => {return codeObject.active});
        /* Now go over all active items, find their index in
         * the fromList to splice them out, toggle their active to
         * false, and then push them into toList
         */
        activeOptions.forEach(option => {
          let index = fromList.indexOf(option);
          fromList.splice(index, 1);
          option.active = !option.active;
          toList.push(option);
        });
    }
    else  {
      // The actual arrays to change are actually the same regular/binary
      let properName = name === "regularSelect" ? "regular" : "binary";
        /*
         * Find the correct item in the
         * regular/binary array and toggle
         * its active attribute
         */
        let option = this.state.[properName]
        .filter(codeObject => {return codeObject.content === value});
        option[0].active = !option[0].active;
      }
    this.setState((state) => {
      return {regular: regular,
        binary: binary,}
      });
    }

    /**
    * Send the data to the MatchingDashboardBackEndCommunicator
    * to be sent to the backend to handle.
    * If I ever decide to communicate directly with the
    * database for performance, I shall change the call here.
    * @implNote Adding an if else to check for a bad submit 3.1.0 21.06.2021 13:43
    * @since 3.0.0 29.04.2021 14:12
    */
    submitForm = () => {
      const {binary} = this.state;
      let newCodes = this.props.codes;
      let shouldBinary = false;
      for (const [code, val] of Object.entries(this.props.codes)) {
        let option = binary
        .filter(codeObject => {
          // console.log(codeObject);
          return codeObject.content === code});
        // console.log(option);
        if (option.length !== 0) {
          shouldBinary = true;
        }
        else {
          shouldBinary = false;
        }
        newCodes.[code].isBinary=shouldBinary;
      }
      console.log(newCodes);
      // with isInclusion set to false
      // 3.1.0 01.07.2021 10:59 now it is submitConfigs and has a isBinary for type at the end
      MatchingDashboardBackEndCommunicator.submitConfigs(newCodes, this.props.matchingName, "isBinary");
      this.props.updateCodes(newCodes, false);
    }

    /* Thanks Boky on https://stackoverflow.com/questions/40989524/how-to-get-id-and-input-data-when-onchange-event-is-happening-react-js
    * for the trick with the bind in order to only have one handleChange function (3.1.0 18.06.2021 <<14:32)
    */
    render() {
      return(
        <Form className="ui centered">
          <div className="ui centered center-stage">
            <Grid>
              <Grid.Column key={0}>
                <h3>Regular calculation list</h3>
                <List selection
                  onItemClick={this.handleChange.bind(this,"regularSelect")}
                  items={this.state.regular} />
              </Grid.Column>
              <Grid.Column key={1}>
                <br/>
                <Button onClick={this.handleChange.bind(this,"binary")}>
                  =>
                </Button>
                <Button onClick={this.handleChange.bind(this,"regular")}>
                  &lt;=
                </Button>
              </Grid.Column>
              <Grid.Column key={2}>
                <h3>Binary calculation list</h3>
                <List selection
                  onItemClick={this.handleChange.bind(this,"binarySelect")}
                  items={this.state.binary} />
              </Grid.Column>
            </Grid>
          </div>
          <div className="buttonGroup">
            <Link className="btn btn-warning" to="/dash/edit_meta" id="backButton">
              Back
            </Link>
            <Button type="submit" className="btn btn-success" id="submitButton"
              onClick={this.submitForm}>
              Submit
            </Button>
          </div>
        </Form>
        );
      }
    }

    export default CalculationForm;
