import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import './css/form.css';
import MatchingDashboardBackEndCommunicator from './MatchingDashboardBackEndCommunicator.js';
import EditScoresMainForm from './FormComponents/EditScoresMainForm';
import EditWeightsForm from './FormComponents/EditWeightsForm';
import DependencyForm from './FormComponents/DependencyForm';
import DependencyRemovalForm from './FormComponents/DependencyRemovalForm';
import InclusionForm from './FormComponents/InclusionForm';
import CalculationForm from './FormComponents/CalculationForm';
import { Container, Modal, Header, Dimmer, Loader} from 'semantic-ui-react';
import { Redirect, Link, Switch, Route } from "react-router-dom";

class Dashboard extends React.Component {
  redirect = null;
  // 3.1.0 18.06.2021 08:51
  questionCodesPulled = false;
  // 3.1.0 18.06.2021 09:44 - for the dependencies etc 28.06.2021 14:13 is now a dictionary
  questionCodes = {};
  // 3.1.0 23.06.2021 13:12
  isPeerMatching = false;
  constructor(props) {
    super(props);
    this.state = {
      /*
      * A boolean to track whether the matching
      * tool's database is setup and current.
      * Will receive an answer about it from
      */
      databaseSetup: false,
      surveyDataLoaded: false,
      /*
      * A boolean to track whether there is
      * a scoring scheme so that the produce_matching
      * button will only be available if there is a
      * scoring scheme available in adddition to user
      * data.
      */
      scoringSchemeFound: false,
      userDataPulled: false,
      /*
      * A boolean to test showing
      * the scoring scheme once
      * it is received.
      * 2.1.0 08.12.2020 14:51
      */
      scoringSchemePulled: false,
      /*
      * A separete boolean to
      * control being able to
      * go back and forth
      * once scoring scheme
      * was pulled
      */
      showScoringScheme: false,
      /*
      * A separete boolean to
      * control being able to
      * see the weights
      * 2.1.0 22.12.2020 11:29
      */
      showWeights: false,
      /*
      * received xml texts
      * 2.1.0 08.12.2020 14:55
      */
      xmlTexts: [],
      /*
      * received xml scores
      * 2.1.0 15.12.2020 14:00
      */
      xmlScores: [],
      /*
      * received xml weights
      * 2.1.0 15.12.2020 14:00
      */
      xmlWeights: [],
      /*
      * received xml weights
      * without isIncluded=false questions
      * 3.1.0 28.06.2021 13:41
      */
      xmlWeightsFiltered: [],
      /*
      * received xml texts
      * without array questions
      * and isIncluded=false questions
      * 2.1.0 16.12.2020 13:25
      */
      xmlTextsFiltered: [],
      /*
      * received xml weights
      * without isIncluded=false questions.
      * Data duplication because other solutions
      * would be too involved and I was not in the mood
      * 3.1.0 28.06.2021 14:45
      */
      xmlTextsFilteredForWeights: [],
      /*
      * received xml scores
      * without array questions
      * and isIncluded=false questions
      * 2.1.0 16.12.2020 13:25
      */
      xmlScoresFiltered: [],
      /*
      * Added this to have the
      * xmlDocument from the backend
      * and then update it when needed
      * and send it back
      * 2.1.0 17.12.2020 12:48
      */
      xmlDocument: {},
      /*
      * The name of the matching meta
      * currently in use.
      * 3.0.0 27.04.2021 11:04
      */
      matchingName: this.props.matchingName,
      /*
      * The name of the logged
      * in user.
      * It will be hardcoded until I
      * implement the auth/sso stuff
      * 3.0.0 26.05.2021 11:28 - no longer hardcoded
      * 3.0.0 27.04.2021 11:06
      */
      username: this.props.username,
      /*
       * Adding a modal to inform user
       * about unsuccessful matching
       * 3.1.1 22.07.2021 17:08
       */
      showModal: false,
      /*
       * This array will replace ALL old
       * objects related to scores editing
       * with the upgrade to the db based
       * scoring scheme instead of the xml
       * based one.
       * STRUCTURE:
       * {code: "...", answers: Array(6), questionText: "..."}
       * WHERE the elements of the answers array look like:
       * {score: ..., answerText: "...", letter: "..."}
       * @since 3.2.0 27.07.2021 22:46
       */
      scores: [],
      /*
       * This will show a loader as long as the matching is not finished
       * @implNote 3.2.0.28.07.2021 14:18 will use it in the edit_meta
       * part as well. maybe even the create meta part
       * @since 3.2.0 28.07.2021 13:56
       */
      showLoader: false,
      /**
      * This will hold the
      * current chosenMeta isOwner
      * @since 4.0.0 10.09.2021 12:31
      */
      chosenMetaIsOwner: this.props.chosenMetaIsOwner,
    }
    this.produceMatching = this.produceMatching.bind(this);
    this.getScores = this.getScores.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.submitScores = this.submitScores.bind(this);
    this.updateLocalScores = this.updateLocalScores.bind(this);
    this.submitWeights = this.submitWeights.bind(this);
    this.updateLocalWeights = this.updateLocalWeights.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.triggerLoader = this.triggerLoader.bind(this);
  }

  produceMatching() {
    // 3.2.0 28.07.2021 13:58 we're adding a loader now
    this.triggerLoader(true);
    MatchingDashboardBackEndCommunicator.produceMatching(this.state.matchingName) // Added matching name 3.0.0 04.05.2021 13:17
    .then(
      response => {
        console.log(response);
        // console.log(response.data);
        // console.log(response.data.error);
        //alert(response);
        // 3.2.0 28.07.2021 13:58 we're adding a loader now
        this.triggerLoader(false);
        /*
         * 3.1.1 22.07.2021 17:17
         * If the response has error in it
         * then trigger modal
         */
         if (response.data.error !== undefined) {
           this.triggerModal(true);
         }
      }, (error) => { // This was also added for the modal 3.1.1 22.07.2021 17:21
        this.triggerModal(true);
      }
    );
  }

  /**
   * trigger the loader on and off
   * @since 3.2.0 28.07.2021 13:59
   */
  async triggerLoader(showLoader) {
    //console.log("triggering loader to ", showLoader);
    this.setState((state) => {
      return {
        showLoader: showLoader,
      }
    });
  }

  /**
   * trigger the modal on and off
   * @since 3.1.1 22.07.2021 17:09
   */
  triggerModal(showModal) {
    this.setState((state) => {
      return {
        showModal: showModal,
      }
    });
  }

  /**
  * @apiNote added boolean 2.1.0 22.12.2020 ~13:10
  * @since 2.1.0 04.12.2020 14:51
  * @depracated 3.2.0 27.07.2021 22:47 will now use
  * the new scores array in conjuction with the codes
  * array for score keeping and editing.
  */
  getScores(isScores) {
    MatchingDashboardBackEndCommunicator.getScores(this.state.matchingName) // Added the matchingName parameter 3.0.0 03.05.2021 13:25
    .then(
      response => {
        console.log(response);
        //console.log(response.request.responseText);
        //console.log(response.headers['content-type']);
        //alert(response);
        /*
        * 2.1.0 10.12.2020 12:51
        */
        var doc = new DOMParser().parseFromString(
          response.request.responseText, 'text/xml');
          //console.log(doc);
          /*
          * thanks
          * Reza
          * from
          * https://stackoverflow.com/questions/37260009/how-to-use-xml-response-in-react-native
          */
          var questions = doc.getElementsByTagName('Question');
          //console.log(questions);
          var texts = [];
          var scores = [];
          var weights = [];
          var filteredTexts = [];
          var filteredScores = [];
          // UPGRADE202106281253
          var filteredWeights = [];
          var filteredTextsForWeights = [];
          //var answersText = [];
          /*
          * This was added to allow text
          * and score arrays without array questions
          * 2.1.0 16.12.2020 13:49
          */
          var filteredIndex = 0;
          for (var i=0; i < questions.length; i++) {
            var answers = questions[i].getElementsByTagName('Answer');
            //console.log("The regular index is ", i);
            //console.log("The index for the filtered array is ", filteredIndex);
            /*
            * This was added to allow text
            * and score arrays without array questions
            * 2.1.0 16.12.2020 13:28
            */
            var questionType = questions[i].attributes[1].value;
            //console.log(answers);
            //objs.push({
            //questionText: questions[i].textContent,
            //answer: [...answer, answers[0].textContent]
            //objs = [...objs, [questions[i].textContent]];
            texts = [...texts, [questions[i].childNodes[0].data]];
            //console.log("Question text outside if: ", questions[i].childNodes[0].data);
            //var questionCode = questions[i].attributes[0].value;
            var isIncluded = this.questionCodes[questions[i].attributes[0].value]["isIncluded"];
            //console.log(questionCode);
            // console.log("isIncluded");
            // console.log(isIncluded);
            /*
            * 2.1.0 16.12.2020 13:44
            * ~~~~~~~~~~~~~~~~~~~~~~~
            * 3.1.0 28.06.2021 12:49
            * Since I am now having QuestionConfigs
            * that allows to disable certain questions from
            * a matching, but allows to re enable them later,
            * we don't want to see the disabled one when changing
            * stuff, we will add to all conditions
            * a condition for isIncluded (after pulling it
            * of course). Also create a filteredWeights
            * and a condition for it.
            * UPGRADE202106281253
            */
            if (questionType === "sin" && isIncluded) {
              filteredTexts = [...filteredTexts, [questions[i].childNodes[0].data]];
              //console.log("Question text inside if: ", questions[i].childNodes[0].data);
            }
            /*
            * Add the weight to the weights array.
            * The attribute path,
            * [0].attributes[3].value
            * was taken from the console on the current ui,
            * so I know this is the value of the weight attribute.
            */
            weights = [...weights, questions[i].attributes[3].value];
            // 3.1.0 28.06.2021 14:22
            if (isIncluded) {
              filteredTextsForWeights = [...filteredTextsForWeights, [questions[i].childNodes[0].data]]; // 3.1.0 28.06.2021 14:46
              filteredWeights = [...filteredWeights, questions[i].attributes[3].value];
            }
            /*
            * Now add a new empty sub array to scores
            */
            scores = [...scores, []];
            // 2.1.0 16.12.2020 13:44
            if (questionType === "sin" && isIncluded) {
              filteredScores = [...filteredScores, []];
            }
            for (var j = 0; j < answers.length; j++) {
              //answers[j] is the current answer of questions[i]
              texts[i] = [...texts[i], answers[j].childNodes[0].data];
              // 2.1.0 16.12.2020 13:45
              if (questionType === "sin" && isIncluded) {
                filteredTexts[filteredIndex] =
                [...filteredTexts[filteredIndex],
                answers[j].childNodes[0].data];
              }
              /*
              * Add the score to the scores nested array.
              * The attribute path,
              * [0].attributes[1].value
              * was taken from the console on the current ui,
              * so I know this is the value of the weight attribute.
              */
              scores[i] = [...scores[i], answers[j].attributes[1].value];
              // 2.1.0 16.12.2020 13:46
              if (questionType === "sin" && isIncluded) {
                filteredScores[filteredIndex] =
                [...filteredScores[filteredIndex],
                answers[j].attributes[1].value];
                // DEBUG
                /*console.log("The current score for",
                answers[j].childNodes[0].data,"is ",
                answers[j].attributes[1].value);
                // DEBUG
                console.log("The current score" +
                "in the filteredScores array for",
                filteredTexts[filteredIndex][j+1],"is ",
                filteredScores[filteredIndex][j]);*/
              }

            }
            // 2.1.0 16.12.2020 13:50
            if (questionType === "sin" && isIncluded) {
              filteredIndex++;
            }
            //})
          }
          /*
          * 2.1.0 22.12.2020 11:24
          * Since I will use this method for both scores
          * and weights editing, I will move showScoringScheme: true,
          out of here and into the appropriate wrapper function
          */
          this.setState((state) => {
            return { scoringSchemePulled: true,
              //showScoringScheme: true,
              xmlTexts: texts,
              xmlScores: scores,
              xmlWeights: weights,
              // 2.1.0 16.12.2020 13:46
              xmlTextsFiltered: filteredTexts,
              xmlScoresFiltered: filteredScores,
              // 2.1.0 17.12.2020 13:07
              xmlDocument: doc,
              // 3.1.0 28.06.2021 14:47
              xmlWeightsFiltered: filteredWeights,
              xmlTextsFilteredForWeights: filteredTextsForWeights,
            }
          }
        ); // Added the following 2.1.0 22.12.2020 13:22
        /*if (isScores) {
        this.setState((state) => {
        return {
        showScoringScheme: true,
        showWeights: false,
      }});
    } else {
    this.setState((state) => {
    return {
    showScoringScheme: false,
    showWeights: true,
  }});
}*/
//return (
//<p>
//{response.data}
//</p>
//  );
//console.log("Alright, now you can go on!");
}
);
}

/*
* A method to wrap the getScores
* method. If necessary pulls
* the scoring scheme.
* Otherwise just shows it.
* 2.1.0 11.12.2020 13:45
* Added async await again 2.1.0 22.12.2020 13:01
* Didn't work, so added boolean to getScores
*/
async pullScoresIfNecessaryAndShowThem() {
  /*
  * 3.1.0 17.06.2021 09:43
  * After reading about class variables vs. state
  * (Thanks https://www.seanmcp.com/articles/storing-data-in-state-vs-class-variable/)
  * I realized that if I use a class variable for the redirect this is
  * actually a brilliant way to avoid that data missing bug that plagued
  * my previous attempt, and so this is actually a perfect example of
  * a class variable being better for this task. Its change will not
  * force a re-render, and therefore it will only re-render when the
  * setState is called either in getScores or here, ensuring the data
  * will be in the state before re-render. The trick is to change the
  * variable first and then doing the rest.
  */
  //this.redirect = "/dash/scores";
  if (this.state.scoringSchemePulled === false) {
    // 3.1.0 28.06.2021 13:40 - for isIncluded information
    await this.pullCodesIfNecessary("/dash/scores", false);
    //console.log("let's wait for getScores");
    //await this.getScores(true); // Added a boolean 2.1.0 22.12.2020 ~13:10
    /*
     * 3.2.0 27.07.2021 22:25
     * no longer xml. Now a regular JSON. Since it comes from db
     */
    MatchingDashboardBackEndCommunicator.getScores(this.state.matchingName) // Added the matchingName parameter 3.0.0 03.05.2021 13:25
    .then(response => {
      console.log(response);
      this.setState((state) => {
        return {
          scores: response.data,
          scoringSchemePulled: true,
        }});
        // 3.2.0 28.07.2021 14:19 we're adding a loader now ALSO REPLACES NEED FOR forceUpdate
        this.triggerLoader(false);
    });
    //this.forceUpdate(); // 3.1.0 17.06.2021 12:15 FINALLY! IT FINALLY WORKS! (even though it renders it below) P.S. I don't care it is a hack, at least it works
    //console.log("Did we wait?");
    /*
    * 2.1.0 22.12.2020 11:24
    * Since I will use the getScores method for both scores
    * and weights editing, I will do showScoringScheme: true,
    * for regardless.
    * Because of problems, I reintroduced else and
    * added the setState here as well (~12:59)
    */
    //this.setState((state) => {
    //   return { showScoringScheme: true,}});
  } else {
    /*this.setState((state) => {
    return { showScoringScheme: true,}});
  }*/
  this.redirect = "/dash/scores";
  await this.redirector(true, false); // 3.1.0 17.06.2021 12:35
  this.forceUpdate(); // 3.1.0 17.06.2021 12:18
}
}

/*
* A method to wrap the getScores
* method. If necessary pulls
* the scoring scheme.
* Shows the weights
* 2.1.0 22.12.2020 11:27
* Added async await again 2.1.0 22.12.2020 13:01
*/
async pullWeightsIfNecessaryAndShowThem() {
  //this.redirect = "/dash/weights";
  if (this.state.scoringSchemePulled === false) {
    // 3.1.0 28.06.2021 13:40 - for isIncluded information
    await this.pullCodesIfNecessary("/dash/weights", false);
    //await this.getScores(false); // Added a boolean 2.1.0 22.12.2020 ~13:10
    this.forceUpdate(); // 3.1.0 17.06.2021 12:18
    //  this.setState((state) => {
    //      return { showWeights: true,}});
  } else {
    /*this.setState((state) => {
    return { showWeights: true,}});
  }*/
  this.redirect = "/dash/weights";
  await this.redirector(false, true); // 3.1.0 17.06.2021 12:35
  this.forceUpdate(); // 3.1.0 17.06.2021 12:18
}
}

/**
* A method to test that the whole REST API
* thing is even working
* @since 2.0.0 01.11.2020 22:47
*/
testGet() {
  MatchingDashboardBackEndCommunicator.testGet()
  .then(
    response => {
      console.log(response.data);
      //alert(response.data);
    }
  )
}

/**
* This function is responsible
* for persisting the state
* that was set in any of
* the main forms,
* presumably when they were
* submitted by the user
* @since 2.1.0 15.12.2020 14:28
*/
async submitScores(newScores) {
  this.redirect = "/dash/edit_meta"; // 3.1.0 17.06.2021 12:21
  console.log("submitScores invoked in dashboard");
  console.log("newScores is ", newScores);
  console.log(this.state.scores);
  /*
  * Thanks to some debugging, I found out that the log of the xmlDocument
  * happens before the state is updated, leading to the old state sent
  * back to the backend the first time you go through it.
  * And in fact, each time it will send back the state from one update
  * before.
  * Decided to try to use .then(...) so that the sending to the backend
  * waits for the state to be updated (2.1.0 21.12.2020 19:23)
  * failed, so I will try async/await (19:28)
  */
  //await this.updateLocalScores(newScores);
  // console.log(this.state.xmlDocument);
  /*
  * After the local scores are updated, we can send them to the backend to
  * be saved
  */
  MatchingDashboardBackEndCommunicator.updateScores(this.state.scores);
  // To succssessfully redirect: 3.2.0 28.07.2021 00:09
  await this.redirector(true, false); // 3.1.0 17.06.2021 12:35
  // Finally, set showScoringScheme to false to get back to the dashboard
  /*this.setState((state) => {
  return {
  //    [input]: event.target.value,
  showScoringScheme: false,
}
});*/
this.forceUpdate(); // 3.1.0 17.06.2021 12:22
}

/**
* This function is responsible
* for updating both the local filteredScores
* and the scores.
* @depracated 3.2.0 28.07.2021 12:26 will now use
* the new scores array in conjuction with the codes
* array for score keeping and editing.
* @since 2.1.0 17.12.2020 13:26
*/
updateLocalScores(newScores) {
  var doc = this.state.xmlDocument;
  var questions = doc.getElementsByTagName('Question');
  var filteredIndex = 0;
  var scores = this.state.xmlScores; // First pull the current state of unfiltered scores
  /*
  * Will take the document and parse it to JSON to edit and parse back
  * Thanks https://attacomsian.com/blog/nodjs-edit-xml-file
  */
  const xml2js = require('xml2js');
  // XML builder
  const xmlBuilder = new xml2js.Builder();
  // Get the xml data to JSON and have it reversible
  //var jsonDoc = JSON.parse(parser.toJson(doc.text(), {reversible: true}));
  // convert XML data to JSON object
  var jsonDoc = "";
  // mergeAttrs Will prevent the attributes from being under a weird $ element
  xml2js.parseString(doc.documentElement.outerHTML, (err, result) => {
    if (err) {
      throw err;
    }

    // return JSON object
    //console.log(JSON.stringify(result, null, 4));
    //jsonDoc = JSON.stringify(result);
    jsonDoc = result;

  }); // Use the parser to parse the xml, and then assgin the result to jsonDoc
  //console.log("jsonDoc is ", jsonDoc);
  //console.log("jsonDoc.Questions is ", jsonDoc.Questions);
  //var jsonQuestions = jsonDoc.Questions.Question; // Extract array of questions
  var jsonQuestions = jsonDoc["Questions"]["Question"]; // Extract array of questions
  //console.dir(jsonQuestions);
  for (var i=0; i < questions.length; i++) {
    // have the specific json question
    var jsonQuestion = jsonQuestions[i];
    var questionType = questions[i].attributes[1].value;
    // 3.1.0 28.06.2021 14:24 UPGRADE202106281253
    var isIncluded = this.questionCodes[questions[i].attributes[0].value]["isIncluded"];
    /*
    * If list question, take the updated score from newScores[filteredIndex]
    * and put in scores[i], then
    */
    if (questionType === "sin" && isIncluded) {
      // DEBUG:
      //console.log("Updating scores[i] from ", scores[i],"to ",newScores[filteredIndex]);
      scores[i] = newScores[filteredIndex];
      //console.log("Updated scores[i] to ", scores[i]);
      /*
      * Loop over the json answers to update them.
      * There's probably a better way to do it, but...
      * not now.
      */
      var jsonAnswers = jsonQuestion["Answer"];
      for (var j = 0; j < jsonAnswers.length; j++) {
        // Update the answers
        //console.log("Updating jsonAnswers[j] from ", jsonAnswers[j],"to ",newScores[filteredIndex][j]);
        //jsonAnswers[j] = newScores[filteredIndex][j];
        /*
        * After inspecting it in the console (after finally getting it to
        * work), I need to do jsonAnswers[j].score[0] to access
        * the actual score.
        * 2.1.0 18.12.2020 10:52
        */
        //console.log("Updating jsonAnswers[j].score[0] from ",
        //    jsonAnswers[j].score[0],"to ",newScores[filteredIndex][j]);
        // jsonAnswers[j].score[0] = newScores[filteredIndex][j];
        // In order to get the attributes to register properly later, had to go back to have the weird $
        // DEBUG:
        /*console.log("Updating jsonAnswers[j].score[0] from ",
        jsonAnswers[j].$.score,"to ",newScores[filteredIndex][j]);*/
        //console.log("Before[",i,j,"]: ", jsonAnswers[j].$.score);
        jsonAnswers[j].$.score = newScores[filteredIndex][j];
        //console.log("After[",i,j,"]: ", jsonAnswers[j].$.score);
      }
      /*
      * Think I had a bug here (2.1.0 21.12.2020 19:09)
      * Will the following two lines to try and fix it.
      */
      jsonQuestion["Answer"] = jsonAnswers;
      jsonDoc["Questions"]["Question"][i] = jsonQuestion;
      //console.log("jsonDoc[\"Questions\"][\"Question\"][",i,"] after update is ",
      //  jsonDoc["Questions"]["Question"][i]);
      filteredIndex++;
    }
  }
  // Now stringify the jsonDoc and convert back to xml
  const docString = xmlBuilder.buildObject(jsonDoc);
  /*
  * In order to get a Document object again,
  * we can't just take the result of buildObject.
  * we must use doc.write().
  * From my understanding of the documentation, if we do not use
  * doc.open() first, it will overwrite existing content which is
  * actually what we want.
  * 2.1.0 18.12.2020 11:19
  */
  //const ReactDomServer = require("react-dom/server")
  //doc.open();
  //doc.write(docString);
  //doc.close();
  //doc = ReactDomServer.renderToStaticMarkup(docString);
  /*
  * Eventually, when the things above did not work,
  * I found this: using DOMParser
  * 2.1.0 18.12.2020 11:37
  */
  doc = new DOMParser().parseFromString(docString, "application/xml");
  //console.log(doc);
  /*
  * Update the state so the scores are updated in
  * xmlScores, xmlScoresFiltered,
  */
  this.setState((state) => {
    console.log("Setting updated state");
    return {
      xmlScores: scores,
      xmlScoresFiltered: newScores,
      // 2.1.0 17.12.2020 13:07
      xmlDocument: doc,
    }
  }
);
}

/**
* This function is responsible
* for persisting the state
* that was set in
* the weights form,
* presumably when they were
* submitted by the user
* @since 2.1.0 22.12.2020 12:04
* @depracated 3.1.0 01.07.2021 11:02
*/
async submitWeights(newWeights) {
  this.redirect = "/dash/edit_meta"; // 3.1.0 17.06.2021 12:20
  console.log("submitWeights invoked in dashboard");
  await this.updateLocalWeights(newWeights);
  // console.log(this.state.xmlDocument);
  /*
  * After the local weights are updated, we can send them to the backend to
  * be saved
  */
  MatchingDashboardBackEndCommunicator.updateWeights(this.state.xmlDocument);
  // Finally, set showScoringScheme to false to get back to the dashboard
  /*this.setState((state) => {
  return {
  showWeights: false,
}
});*/
this.forceUpdate(); // 3.1.0 17.06.2021 12:21
}

/**
* Adapted from updateLocalScores
* @depracated 3.2.0 28.07.2021 12:26 will now use
* the new scores array in conjuction with the codes
* array for score keeping and editing.
* @since 2.1.0 22.12.2020 12:06
*/
updateLocalWeights(newWeights) {
  var doc = this.state.xmlDocument;
  var questions = doc.getElementsByTagName('Question');
  var filteredIndex = 0; // 3.1.0 28.06.2021 14:26 UPGRADE202106281253
  var weights = this.state.xmlWeights; // 3.1.0 28.06.2021 14:29 UPGRADE202106281253
  /*
  * Will take the document and parse it to JSON to edit and parse back
  * Thanks https://attacomsian.com/blog/nodjs-edit-xml-file
  */
  const xml2js = require('xml2js');
  // XML builder
  const xmlBuilder = new xml2js.Builder();
  // Get the xml data to JSON and have it reversible
  //var jsonDoc = JSON.parse(parser.toJson(doc.text(), {reversible: true}));
  // convert XML data to JSON object
  var jsonDoc = "";
  // mergeAttrs Will prevent the attributes from being under a weird $ element
  xml2js.parseString(doc.documentElement.outerHTML, (err, result) => {
    if (err) {
      throw err;
    }
    // return JSON object
    jsonDoc = result;

  }); // Use the parser to parse the xml, and then assgin the result to jsonDoc
  var jsonQuestions = jsonDoc["Questions"]["Question"]; // Extract array of questions
  for (var i=0; i < questions.length; i++) {
    // 3.1.0 28.06.2021 14:25UPGRADE202106281253
    var isIncluded = this.questionCodes[questions[i].attributes[0].value]["isIncluded"];
    if (isIncluded) {
      weights[i] = newWeights[filteredIndex];
      jsonQuestions[i].$.weight = newWeights[filteredIndex]; // 3.1.0 28.06.2021 14:32 UPGRADE202106281253 - moved and adjusted
      filteredIndex++;
    }
  }
  jsonDoc["Questions"]["Question"] = jsonQuestions;
  // Now stringify the jsonDoc and convert back to xml
  const docString = xmlBuilder.buildObject(jsonDoc);
  /*
  * In order to get a Document object again,
  * we can't just take the result of buildObject.
  * we must use DOMParser
  * 2.1.0 18.12.2020 11:37
  */
  doc = new DOMParser().parseFromString(docString, "application/xml");
  /*
  * Update the state so the scores are updated in
  * xmlScores, xmlScoresFiltered,
  */
  this.setState((state) => {
    return {
      xmlWeights: weights,
      xmlWeightsFiltered: newWeights,
      xmlDocument: doc,
    }
  });
}

/**
* This function is responsible
* for cancling input for
* the main forms without persisting
* their state,
* presumably when the user
* pressed 'Back to dashboard (undo changes)'
* @since 2.1.0 15.12.2020 14:28
*/
cancelForm = async () => {
  this.redirect = "/dash/edit_meta"; // 3.1.0 17.06.2021 12:21
  console.log("cancelForm invoked in dashboard");
  // it does it by simply setting showScoringScheme to false without anything else
  /*this.setState((state) => {
  return {
  showScoringScheme: false,
  showWeights: false,
}});*/
await this.redirector(false, false); // 3.1.0 17.06.2021 12:31
// console.log("and another re render");
this.forceUpdate(); // 3.1.0 17.06.2021 12:21
//this.forceUpdate(); // 3.1.0 17.06.2021 12:23 Yes, using it twice is probably very bad form, but I just want it to work. Anyone else working on this is welcome to fix this
}

// 3.1.0 17.06.2021 12:30 Yes, this is probably very bad form, but I just want it to work. Anyone else working on this is welcome to fix this
redirector = (isScores, isWeights) => {
  // console.log("redirector");
  this.setState((state) => {
    return {
      showScoringScheme: isScores,
      showWeights: isWeights,
    }});
  }

  /**
  * @since 3.0.0 28.05.2021 15:51
  */
  logout = () => {
    this.props.logout();
    this.setState((state) => {
      return {
        username: "",
      }});
    }

    /*
    * 3.1.0 18.06.2021 09:44
    * ~!~!~!~!~!
    * UPDATE 3.1.0 28.06.2021 13:37
    * With a new inclusion of the isInluded and
    * isBinary in the codes object, it is now relevant
    * to the scores and weights editing.
    * Therefore, I need to pull it there without redirecting.
    * Which is why I'm adding a bool.
    * UPGRADE202106281253
    */
    async pullCodesIfNecessary(path, shouldRedirect) {
      // 3.2.0 28.07.2021 14:21 we're adding a loader now
      await this.triggerLoader(true);
      this.redirect = path;
      if (!this.questionCodesPulled) {
        await MatchingDashboardBackEndCommunicator.getCodes(this.state.matchingName).then(response => {
          console.log(response);
          // 3.1.0 23.06.2021 13:11 - now it contains isPeerMatching and the codes array
          this.questionCodes = response.data["codes"];
          this.questionCodesPulled = true;
          this.isPeerMatching = response.data["isPeerMatching"];
        });
      }
      if (shouldRedirect) {
        // 3.2.0 28.07.2021 14:21 we're adding a loader now ALSO REPLACES NEED FOR forceUpdate
        await this.triggerLoader(false);
        await this.redirector(false, false); // 3.1.0 17.06.2021 12:31
        // console.log("and another re render");
        //this.forceUpdate(); // 3.1.0 17.06.2021 12:21
      }
    }

    /**
    * For the inclusion and binary calculation
    * forms
    * (13:23) Since I will use it for the CalculationForm
    * as well, and it does not matter for the filtered lists,
    * I added a boolean to control for this
    * @since 3.1.0 29.06.2021 09:24
    */
    updateCodes = async (newCodes, shouldUpdateLists) => {
      this.redirect = "/dash/edit_meta";
      this.questionCodes = newCodes;
      /*
      * DAMMIT (3.1.0 29.06.2021 12:33)
      * I need to update the lists of scores/filteredScores
      * etc...
      * ~!~!~!~!~!~!
      * (12:59) this.state.xmlWeightsFiltered.length > 0
      * is to make sure we update the lists ONLY
      * if we already pulled them.
      * Otherwise they're not there and will
      * be pulled when we want to edit scores
      * or weights
      * ~!~!~!~!~!~!!~
      * 3.1.0 01.07.2021 11:43 forgot to include shouldUpdateLists
      * doing so now.
      */
      if (this.state.xmlWeightsFiltered.length > 0 && shouldUpdateLists) {
        console.log("let's update the filtered lists");
        await this.updateFilteredLists();
      } else {
        await this.redirector(false, false);
      }
      this.forceUpdate();
    }

    /**
    * Helper method to update the filtered lists
    * IF NECESSARY
    * @depracated 3.2.0 28.07.2021 12:27 will now use
    * the new scores array in conjuction with the codes
    * array for score keeping and editing.
    * @since 3.1.0 29.06.2021 12:58
    */
    updateFilteredLists() {
      var doc = this.state.xmlDocument;
      //console.log(doc);
      var questions = doc.getElementsByTagName('Question');
      var filteredTexts = [];
      var filteredScores = [];
      // UPGRADE202106281253
      var filteredWeights = [];
      var filteredTextsForWeights = [];
      var filteredIndex = 0;
      for (var i=0; i < questions.length; i++) {
        var answers = questions[i].getElementsByTagName('Answer');
        var codeObject = this.questionCodes[questions[i].attributes[0].value];
        //console.log(codeObject);
        var isIncluded = codeObject["isIncluded"];
        // All sorts of stuff from getScores
        var questionType = questions[i].attributes[1].value;
        if (isIncluded) {
          filteredTextsForWeights = [...filteredTextsForWeights, [questions[i].childNodes[0].data]];
          filteredWeights = [...filteredWeights, questions[i].attributes[3].value];
        }
        if (questionType === "sin" && isIncluded) {
          filteredTexts = [...filteredTexts, [questions[i].childNodes[0].data]];
          filteredScores = [...filteredScores, []];
          for (var j = 0; j < answers.length; j++) {
            //answers[j] is the current answer of questions[i]
            //if (questionType === "sin" && isIncluded) {
              filteredTexts[filteredIndex] =
              [...filteredTexts[filteredIndex],
              answers[j].childNodes[0].data];
            //}
            //if (questionType === "sin" && isIncluded) {
              filteredScores[filteredIndex] =
              [...filteredScores[filteredIndex],
              answers[j].attributes[1].value];
            //}
          }
          filteredIndex++;
        }
      }
      this.setState((state) => {
        return { xmlTextsFiltered: filteredTexts,
          xmlScoresFiltered: filteredScores,
          xmlWeightsFiltered: filteredWeights,
          xmlTextsFilteredForWeights: filteredTextsForWeights,
        }
      }
    );
  }

  /*
  * 2.1.0 08.12.2020 14:49
  * ~~~~~~~~~~~~~~~~~~~~~~
  * Realized, that I can use
  * coditionals here to change
  * what's shown to the user.
  */
  render() {
    /*
    * 3.0.0 26.05.2021 13:29
    * added the if for refirection (second if)
    */
    if(this.state.username === "") { // 3.0.0 27.05.2021 13:25
      return(

        <Redirect to="/login" />
      );
    }
    else if (this.state.matchingName === "") {
      return (
        <Redirect to="/" />
      );
    }
    else if (this.redirect) { // 3.1.0 17.06.2021 09:47
      const redirect = this.redirect;
      this.redirect = null;
      console.log("I am trying to redirect to " + redirect);
      return(<Redirect to={redirect} />);
    }
    else {
      //console.log("I am trying to render the path " + this.props.match.path);
      console.log("I am trying to render inside Dashboard")
      // console.log(this.questionCodes);
      //console.log(this.state.xmlWeightsFiltered);
      /*
      There might be other things
      here as well before the return
      */
      /*
      * Replaced button with Button from bootstrap
      * 2.1.0 11.12.2020 12:38
      *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      * Changed second button's onClick to
      * pullScoresIfNecessaryAndShowThem
      * 2.1.0 11.12.2020 13:48
      *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      * Changed the first if to check for showScoringScheme AND
      * showWeights. Then an else if for the scores and an
      * else if for the weights.
      *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      * Added the Modal to inform user of failed matching
      * 3.1.1 22.07.2021 17:12
      *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      * 3.2.0 05.08.2021 16:42
      * Added Headers with the meta name for clarity
      */
      //if ((!this.state.showScoringScheme) && (!this.state.showWeights)) {
      return (
        <Container textAlign='center' fluid className='width-override center-stage'>
          <Switch>
            <Route path="/dash/scores">
              <EditScoresMainForm
                xmlTexts={this.state.xmlTextsFiltered}
                xmlScores={this.state.xmlScoresFiltered}
                showScoringScheme={this.state.showScoringScheme}
                cancelForm={this.cancelForm}
                submitForm={this.submitScores}
                codes={this.questionCodes}
                scores={this.state.scores}
              />
            </Route>
            <Route path="/dash/weights">
              <EditWeightsForm
                /*xmlTexts={this.state.xmlTextsFilteredForWeights}
                xmlWeights={this.state.xmlWeightsFiltered}
                showWeights={this.state.showWeights}*/
                /*cancelForm={this.cancelForm}*/
                /*submitForm={this.submitWeights}*/
                codes={this.questionCodes}
                matchingName={this.state.matchingName}
                updateCodes={this.updateCodes}
              />
            </Route>
            <Route path="/dash/create_dependency">
              <DependencyForm
                codes={this.questionCodes}
                getBack={this.cancelForm}
                matchingName={this.state.matchingName}
                isPeerMatching={this.isPeerMatching}
              />
            </Route>
            <Route path="/dash/remove_dependencies">
              <DependencyRemovalForm
                getBack={this.cancelForm}
                matchingName={this.state.matchingName}
              />
            </Route>
            <Route path="/dash/edit_inclusion">
              <InclusionForm
                codes={this.questionCodes}
                //getBack={this.cancelForm}
                matchingName={this.state.matchingName}
                updateCodes={this.updateCodes}
              />
            </Route>
            <Route path="/dash/edit_calculation">
              <CalculationForm
                codes={this.questionCodes}
                //getBack={this.cancelForm}
                matchingName={this.state.matchingName}
                updateCodes={this.updateCodes}
              />
            </Route>
            <Route path="/dash/edit_meta">
              <div className="dashboard">
                <Header size='large' textAlign='center'>{this.state.matchingName}</Header>
                <Button className="btn btn-light"
                  onClick={async () => await this.pullCodesIfNecessary("/dash/edit_inclusion", true)}>
                  Edit Question Inclusion
                </Button>
                <Button onClick={() => this.pullScoresIfNecessaryAndShowThem()}>
                  Edit Scoring Scheme
                </Button>
                <Button className="btn btn-secondary"
                  /*onClick={() => this.pullWeightsIfNecessaryAndShowThem()}*/
                  onClick={async () => await this.pullCodesIfNecessary("/dash/weights", true)}>
                  Edit weights
                </Button>
                <Button className="btn btn-info"
                  onClick={async () => await this.pullCodesIfNecessary("/dash/create_dependency", true)}>
                  Create Dependency
                </Button>
                <Link className="btn btn-danger"
                to="/dash/remove_dependencies">
                  Remove Dependencies
                </Link>
                <Button className="btn btn-dark"
                  onClick={async () => await this.pullCodesIfNecessary("/dash/edit_calculation", true)}>
                  Edit Question Calculation
                </Button>
                <Link className="btn btn-warning" to="/dash">
                  Back
                </Link>
                <Dimmer active={this.state.showLoader}>
                  <Loader indeterminate>Pulling data from database</Loader>
                </Dimmer>
              </div>
            </Route>
            <Route path="/dash">
              <div className="dashboard">
                <Header size='large'>{this.state.matchingName}</Header>
                <Button className="btn btn-success" onClick={() => this.produceMatching()}>
                  Produce matching
                </Button>
                {/*<Button onClick={() => this.pullScoresIfNecessaryAndShowThem()}>
                  Edit Scoring Scheme
                  </Button>
                  <Button className="btn btn-secondary"
                  onClick={() => this.pullWeightsIfNecessaryAndShowThem()}>
                  Edit weights
                </Button>*/}
                <Link className="btn btn-primary" to="/dash/edit_meta" >
                  Edit Matching
                </Link>
                <Link className="btn btn-info" to="/"
                onClick={() => this.props.toggle(this.state.matchingName, this.state.chosenMetaIsOwner)}>
                  {/*<Button className="btn btn-success" onClick={() => this.props.toggle(this.state.matchingName)}>*/}
                  Replace matching
                  {/* </Button> */}
                </Link>
                <Button className="btn btn-danger" onClick={() => this.logout()}>
                  Logout
                </Button>
                <Modal
                  dimmer='inverted'
                  size='small'
                  closeIcon
                  open={this.state.showModal}
                  onClose={() => this.triggerModal(false)}
                  onOpen={() => this.triggerModal(true)}
                >
                  <Header content='Matching failed' />
                  <Modal.Content>
                    <p>
                      An exception was raised during the matching
                      process, causing it to fail
                    </p>
                  </Modal.Content>
                </Modal>
                <Dimmer active={this.state.showLoader}>
                  <Loader indeterminate>Matching</Loader>
                </Dimmer>
              </div>
            </Route>
          </Switch>
        </Container>
);
/*} else if (this.state.showScoringScheme) {
return (
<Container textAlign='center' text>
<EditScoresMainForm
xmlTexts={this.state.xmlTextsFiltered}
xmlScores={this.state.xmlScoresFiltered}
showScoringScheme={this.state.showScoringScheme}
cancelForm={this.cancelForm}
submitForm={this.submitScores}
/>
</Container>
);
} else if (this.state.showWeights) {
return (
<Container textAlign='center' text>
<EditWeightsForm
xmlTexts={this.state.xmlTexts}
xmlWeights={this.state.xmlWeights}
showWeights={this.state.showWeights}
cancelForm={this.cancelForm}
submitForm={this.submitWeights}
/>
</Container>
);
}*/

}
}
}

export default Dashboard;
