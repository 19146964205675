import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import './css/form.css';
import MatchingDashboardBackEndCommunicator from './MatchingDashboardBackEndCommunicator.js';
import CreateNewMatchingMetaForm from './FormComponents/CreateNewMatchingMetaForm';
//import { Container } from 'semantic-ui-react';
import { Dropdown, Modal, Header, Container, Dimmer, Loader } from 'semantic-ui-react';
import { Link, Redirect, Switch, Route } from "react-router-dom";

/*
 * GENERAL NOTE 3.0.0 26.05.2021 13:16 -
 * Wrapping the buttons in <Link className="btn btn-success" to="/???"></Link>
 * Seems to be enough to achieve the desired result.
 * That means, I don't need to do any super fancy retrospective changes.
 * There is one problem though...
 * We need to somehow only allow it only with proper stuff.
 * I think I might try redirection??
 *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * UPDATE(13:40): In order not to have button inside a button,
 * I simply moved the onClick to the Link component and commented out the
 * Button one. Also in other files..
 */
class MatchingMetaDashboard extends React.Component {
  // 3.3.0 06.08.2021 16:20
  metaDataPulled = false;
  metaData = {};
  redirect = null; // 3.3.0 06.08.2021 16:56
  constructor(props) {
    super(props);
    this.state = {
      /*
      * The name of the logged
      * in user.
      * It will be hardcoded until I
      * implement the auth/sso stuff
      3.0.0 26.05.2021 11:31 - no longer hardcoded
      * 3.0.0 27.04.2021 11:06
      */
      username: this.props.username,
      /**
      * This will hold the acceptable
      * matching meta names for the user
      * @since 3.0.0 27.04.2021 13:18
      */
      metaNames: this.props.metaNames,
      /**
      * This will hold the
      * current chosenMeta
      * @since 3.0.0 27.04.2021 13:52
      */
      chosenMeta: this.props.chosenMeta,
      /**
      * This will hold the
      * toggle state for creating a new
      * meta
      * @since 3.0.0 28.04.2021 14:0
      */
      isCreation: false,
      /*
       * Adding a modal to inform user
       * about nonexistant survey
       * @since 3.2.0 28.07.2021 15:12
       MOVED HERE 15:58
       */
      showModal: false,
      /**
       * This will show a loader as long as the matching meta is pulled
       * from backend
       * @since 3.3.0 06.08.2021 17:33
       */
      showLoader: false,
      /**
      * This will hold the
      * current chosenMeta isOwner
      * @since 4.0.0 10.09.2021 11:52
      */
      chosenMetaIsOwner: this.props.chosenMetaIsOwner,
      /**
      * Now that I'm using the index of the name object
      * as value for the dropdown, I need to record it as well
      * for the dropdown to show up properly
      * @since 4.0.0 10.09.2021 12:43
      */
      chosenMetaIndex: -1,
    }
    this.getMetaNames = this.getMetaNames.bind(this);
    this.prepareOptions = this.prepareOptions.bind(this);
    this.toggleCreation = this.toggleCreation.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    // Only calls backend if we don't have metaNames
    // Added the non empty username 3.0.0 28.05.2021 12:36
    if (this.props.metaNames.length === 0 && this.state.username !== "") {
      console.log("calling the backend for meta names");
      this.getMetaNames(this.state.username);
    }
    else if (this.state.username === "") {
      console.log("Not calling backend fot meta names for empty username");
    }
  }

  /**
   * trigger the modal on and off
   * @since 3.2.0 28.07.2021 15:13
   * MOVED HERE 15:59
   */
  triggerModal(showModal) {
    this.setState((state) => {
      return {
        showModal: showModal,
      }
    });
  }

  /**
  * A method to test that the whole REST API
  * thing is even working
  * @since 3.0.0 27.04.2021 <13:00
  */
  getMetaNames(username) {
    MatchingDashboardBackEndCommunicator.getNames(username)
    .then(
      response => {
        //console.log(response.data);
        //alert(response.data);
        //console.log(response.data.metas.flatMap(meta => meta.matchingName));
        this.setState((state) => {
          return {
            metaNames: response.data.metas,
          }});
          console.log(this.state.metaNames);
          // Propagate change upwards
          this.props.saveMetaNames(this.state.metaNames);

        }

      )
    }

    /**
     * @implNote 4.0.0 10.09.2021 12:02
     * I will make the value the index in the metaNames array
     * now that it contains both the name and the isOwner boolean.
     * The rest of the changes stem from those facts
     */
    handleChange = (e, { value }) => {
      console.log(value);
      var metaNameObj = this.state.metaNames[value];
      // console.log(metaNameObj);
      const metaName = metaNameObj.name;
      // console.log(metaName);
      // 3.3.0 30.08.2021 14:22 - when changing the dropdown, it forces loading the meta data again
      this.metaDataPulled = this.state.chosenMeta === metaName;
      this.setState((state) => {
        return {
          chosenMeta: metaNameObj.name,
          chosenMetaIsOwner: metaNameObj.isOwner,
          chosenMetaIndex: value, // 4.0.0 10.09.2021 12:44
        }
      });
    }

      /**
      * A method to get the dropdown
      * working
      * @implNote 4.0.0 10.09.2021 11:25
      * Since now I'm returning names and isOwner, I need to adjust
      * the text and value;
      * 4.0.0 10.09.2021 12:02
      * I will make the value the index in the metaNames array
      * now that it contains both the name and the isOwner boolean
      * @since 3.0.0 27.04.2021 13:50
      */
      prepareOptions() {
        var metaNames = this.state.metaNames;
        var options = [];
        var i;
        for(i=0; i < metaNames.length; i++){
          options[i] = {key: i, text: metaNames[i].name, value: i}
        }
        return options;
      }

      /**
      * This function toggles between
      * meta and regular dashboard
      * @implNote Makes sense to take the new
      * meta and propogate it 3.0.0 04.05.2021 10:12
      * @implNote Tiny change: isCreation is either toggled with
      * the new meta update, or separately otherwise. Also,
      * saveMetanNames will be called first
      * @since 3.0.0 27.04.2021 11:28
      */
      toggleCreation = (newMeta) => {
          this.redirect = "/"; // 3.3.0 06.08.2021 16:55
          if (newMeta != null) {
            // Propagate change upwards
            this.props.saveMetaNames(this.state.metaNames);
            this.setState((state) => {
              return {
                metaNames: [...this.state.metaNames, newMeta],
                chosenMeta: newMeta,
                // isCreation: !this.state.isCreation,
              }});
          }
          else {
            // this.setState((state) => {
            //   return {
            //     isCreation: !this.state.isCreation,
            //   }});
            this.forceUpdate();
          }
          this.forceUpdate();
      }

      /**
       *
       * @since 3.3.0 06.08.2021 ~17:30
       */
      async pullMetaIfNecessary(path, shouldRedirect) {
        await this.triggerLoader(true);
        this.redirect = path;
        if (!this.metaDataPulled) {
          await MatchingDashboardBackEndCommunicator.getMatchingMeta(this.state.chosenMeta).then(response => {
            console.log(response);
            this.metaData = response.data;
            this.metaDataPulled = true;
          });
        }
        if (shouldRedirect) {
          await this.triggerLoader(false);
          this.forceUpdate();
        }
      }

      /**
       * trigger the loader on and off
       * @since 3.3.0 06.08.2021 17:32
       */
      async triggerLoader(showLoader) {
        this.setState((state) => {
          return {
            showLoader: showLoader,
          }
        });
      }

      /**
       * @since 3.0.0 28.05.2021 ~15:45
       */
      logout = () => {
        this.props.logout();
        this.setState((state) => {
          return {
            username: "",
          }});
      }

      /*
      *
      */
      render() {
        // console.log("Current matching name",this.state.chosenMeta);
        // console.log("MatchingMetaDashboard isOwner: ", this.state.chosenMetaIsOwner);
        var options = this.prepareOptions();
        if(this.state.username === "") { // 3.0.0 27.05.2021 13:24
          return(
              <Redirect to="/login" />
            );
        }
        else if (this.redirect) { // 3.1.0 17.06.2021 09:47
          const redirect = this.redirect;
          this.redirect = null;
          console.log("I am trying to redirect to " + redirect);
          return(<Redirect to={redirect} />);
        }
        // else if (this.state.isCreation) {
        //   return(
        //     <CreateNewMatchingMetaForm
        //       toggleCreation={this.toggleCreation}
        //       username={this.state.username}
        //       triggerModal={this.triggerModal}
        //     />
        //   );
        // }
        else {
          return (
            <Container textAlign='center' fluid className='width-override center-stage'>
              <Switch>
                <Route path="/create_meta">
                  <CreateNewMatchingMetaForm
                    toggleCreation={this.toggleCreation}
                    username={this.state.username}
                    triggerModal={this.triggerModal}
                    metaName={""}
                    metaType={'REGULAR'}
                    maxGroupSize={1}
                    mentorSurveyId={-1}
                    menteeSurveyId={-1}
                    leaderChoiceMethod={'RANDOM'}
                    matchingMethod={'OPTIMAL'}
                    edit={false}
                  />
                </Route>
                <Route path="/change_meta">
                  <CreateNewMatchingMetaForm
                    toggleCreation={this.toggleCreation}
                    username={this.state.username}
                    triggerModal={this.triggerModal}
                    metaName={this.metaData.metaName}
                    metaType={this.metaData.metaType}
                    maxGroupSize={this.metaData.maxGroupSize}
                    mentorSurveyId={this.metaData.mentorSurveyId}
                    menteeSurveyId={this.metaData.menteeSurveyId}
                    //leaderChoiceMethod={this.metaData.leaderChoiceMethod}
                    leaderChoiceMethod={this.metaData.mentorSurveyId === -1
                      ? 'RANDOM' : this.metaData.mentorSurveyId === 0
                        ? 'SAME' : 'SEPARATE'}
                    matchingMethod={this.metaData.matchingMethod}
                    edit={true}
                  />
                </Route>
                <Route>
                  <div className="dashboard">
                    <Dropdown
                      onChange={this.handleChange}
                      options={options}
                      placeholder='Choose a matching'
                      selection
                      search
                      value={this.state.chosenMetaIndex}
                    />
                    <Link className="btn btn-success" to="/dash"
                      onClick={() => this.props.toggle(this.state.chosenMeta, this.state.chosenMetaIsOwner)}>
                      {/*<Button className="btn btn-success" onClick={() => this.props.toggle(this.state.chosenMeta)}>*/}
                      Choose matching
                      {/*</Button>*/}
                    </Link>
                    <Link className="btn btn-primary" to="/create_meta"> {/*onClick={() => this.toggleCreation()}>*/}
                      Create new matching
                    </Link>
                    <Button className="btn btn-secondary"
                      /*onClick={() => this.pullWeightsIfNecessaryAndShowThem()}*/
                      onClick={async () => await this.pullMetaIfNecessary("/change_meta", true)}>
                      Change current matching
                    </Button>
                    <Button className="btn btn-danger" onClick={() => this.logout()}>
                      Logout
                    </Button>
                    <Modal
                      dimmer='inverted'
                      size='small'
                      closeIcon
                      open={this.state.showModal}
                      onClose={() => this.triggerModal(false)}
                      onOpen={() => this.triggerModal(true)}
                    >
                      <Header content='Nonexistant survey' />
                      <Modal.Content>
                        <p>
                          There was a survey id that did not correspond
                          to an existing survey.
                          <br />
                          Therefore, the meta was not saved
                        </p>
                      </Modal.Content>
                    </Modal>
                    <Dimmer active={this.state.showLoader}>
                      <Loader indeterminate>Pulling the matching details</Loader>
                    </Dimmer>
                  </div>
                </Route>
              </Switch>
            </Container>

          );
        }
      }
    }

    export default MatchingMetaDashboard;
