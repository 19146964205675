/**
 * The main Component to handle the weight
 * editing
 * @api note - Adapted from EditScoresMainForm
 * @since 2.1.0 22.12.2020 11:38
 */
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import '../css/form.css';
//import { Button } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';

class EditWeightsForm extends Component {
  constructor(props) {
    super(props);
    let weights = [];
    // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
    // codes is map, so we take keys
    for (const [code, val] of Object.entries(this.props.codes)) {
      if (val["isIncluded"]) { // Only show included questions in the weight form 3.1.0 01.07.2021 11:39
        var weightObject = {code: code, weight: val.weight};
        weights.push(weightObject);
      }
    }
    this.state = {
      //xmlTexts: this.props.xmlTexts,
      //xmlWeights: this.props.xmlWeights,
      //showWeights: this.props.showWeights,
      weights: weights
    }
    //this.submitChanges = this.submitChanges.bind(this);
    //this.pullTextsAndWeights = this.pullTextsAndWeights.bind(this);
    //this.handleChange = this.handleChange.bind(this);
  }

    /**
     * Taken as is from the example
     * @since 2.1.0 22.12.2020 11:38
     */
    handleChange = input => event => {
      console.log("The input here is ", input);
      console.log("The value here is ", event.target.value);
      //const con = this.state.xmlScores[this.state.step - 1][input];
      //console.log("We want to update ", con);
      /*
       * FINALLY! Got it to update
       * properly (2.1.0 16.12.2020 14:53)
       * thanks to mpen from
       * https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
       * for saving me here. The following is an adaptation of his answer.
       */
      // 1. Make a shallow copy of the items
      let weights = [...this.state.weights];
      // 2. Make a shallow copy of the item you want to mutate
      let weight = weights[input];
      console.log("We want to update ", weight);
      // 3. Replace the property you're intested in
      weight = event.target.value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      // 3.1.0 01.07.2021 11:41 added .weight to conform to new weights in db update
      weights[input].weight = parseInt(weight);
      // 5. Set the state to our new copy
        this.setState((state) => {
          return {
            weights: weights,
          }
        });
    }

    /**
     * Submit the changes to the dashboard.
     * @depracated 3.1.0 01.07.2021 11:53
     * @since 2.1.0 22.12.2020 11:38
     */
    submitChanges() {
      this.props.submitForm(this.state.xmlWeights);
    }

    /**
    * Send the data to the MatchingDashboardBackEndCommunicator
    * to be sent to the backend to handle.
    * If I ever decide to communicate directly with the
    * database for performance, I shall change the call here.
    * @since 3.1.0 01.07.2021 11:07
    */
    submitForm = () => {
      let newCodes = this.props.codes;
      const weights = this.state.weights;
      for (var i = 0; i < weights.length; i++) {
        const code = weights[i].code;
        const weight = weights[i].weight;
        newCodes.[code].weight=weight;
      }
      console.log(newCodes);
      // 3.1.0 01.07.2021 11:05 submitConfigs and has a weight for type at the end
      MatchingDashboardBackEndCommunicator.submitConfigs(newCodes, this.props.matchingName, "weight");
      this.props.updateCodes(newCodes, false);
    }

    /**
      * @depracated 3.1.0 01.07.2021 <11:53
      * @since 2.1.0 22.12.2020 11:43
      */
    pullTextsAndWeights(questionTexts, weights) {
      const formFields = [];
      for (var i = 0; i < questionTexts.length; i++) {
        /*
         * Here we push a Form.field
         * to the array.
         * The label is questionTexts[i][0] (go to correct
         * question and pull the first element a.k.a the question text),
         * and the default value is the
         * current weight for this question
         */
        formFields.push(
          <Form.Field key={i + 10000}>
            <label>{questionTexts[i][0]}</label>
            <input
              defaultValue={weights[i]}
              onChange={this.handleChange(i)}
                    />
          </Form.Field>
        );

      }
      return(formFields);
    }

    /**
      *
      * @since 3.1.0 01.07.2021 11:33
      */
    prepareForm() {
      const weights = this.state.weights;
      const formFields = [];
      for (var i = 0; i < weights.length; i++) {
        /*
         * Here we push a Form.field
         * to the array.
         * The label is questionTexts[i][0] (go to correct
         * question and pull the first element a.k.a the question text),
         * and the default value is the
         * current weight for this question
         */
        formFields.push(
          <Form.Field key={i + 10000}>
            <label>{weights[i].code}</label>
            <input
              defaultValue={weights[i].weight}
              onChange={this.handleChange(i)}
                    />
          </Form.Field>
        );

      }
      return(formFields);
    }

    /**
     *
     * @since 2.1.0 22.12.2020 11:38
     */
    render(){
      console.log("Inside weights form");
      /*
       * First pull the texts and weights into an
       * array of form fields
       * ~~~~~~~~~~
       * 3.1.0 01.07.2021 11:35
       * prepareForm now
       */
      let formFields = this.prepareForm();
      return(
        <Form >
          <h1 className="ui centered">The Weights</h1>
          {formFields}
          <div className="buttonGroup">
          <Link className="btn btn-warning" to="/dash/edit_meta" id="backButton">
            Back to dashboard (undo changes)
          </Link>
          <Button type="submit" id="submitButton" className="btn-success"
          onClick={this.submitForm} >
             Submit weights
           </Button>
           </div>
        </Form>
      )
    }

}

export default EditWeightsForm;
