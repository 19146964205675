/**
 * The main Component to handle the score
 * editing
 * @api note - Adapted from the example
 * in https://www.digitalocean.com/community/tutorials/how-to-create-multistep-forms-with-react-and-semantic-ui
 * @since 2.1.0 15.12.2020 13:22
 */
import React, { Component } from 'react';
import EditScoresQuestionForm from './EditScoresQuestionForm';
import '../css/form.css';
//import { Button } from 'semantic-ui-react';

class EditScoresMainForm extends Component {
  constructor(props) {
    super(props);
    let scores = [];
    // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
    // codes is map, so we take keys
    for (var i = 0; i < this.props.scores.length; i++) {
      let code = this.props.scores[i]["code"];
      if (this.props.codes[code]["isIncluded"]) { // Only show included questions in the weight form 3.1.0 01.07.2021 11:39
        scores.push(this.props.scores[i]); // Unfortunately too much effort to completely decouple this from original object
        //let score = {}; // This should do a non referencing copy 3.2.0 27.07.2021 23:47
        //Object.assign(score, this.props.scores[i]); // This should do a non referencing copy 3.2.0 27.07.2021 23:45
        //let score = Object.create(this.props.scores[i]); // This should do a non referencing copy 3.2.0 27.07.2021 23:45
        //Object.assign(score["answers"], this.props.scores[i]["answers"]);
        //scores.push(score);
      }
    }
    this.state = {
      step: 1,
      xmlTexts: this.props.xmlTexts,
      xmlScores: this.props.xmlScores,
      showScoringScheme: this.props.showScoringScheme,
      /*
       * for new db based scoring scheme 3.2.0 27.07.2021 22:50
       * STRUCTURE:
       * {code: "...", answers: Array(6), questionText: "..."}
       * WHERE the elements of the answers array look like:
       * {score: ..., answerText: "...", letter: "..."}
       * UPDATE: 3.2.0 27.07.2021 23:34
       * Decided to make a copy that has already filtered non
       * included stuff out, using a for loop over
       * the codes up there ^^
       */
      scores: scores,
    }
    //this.submitForm = this.props.submitForm;
    this.submitChanges = this.submitChanges.bind(this);
  }

  /**
   * Taken as is from the example
   * @since 2.1.0 15.12.2020 13:29
   */
  nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    /**
     * Taken as is from the example
     * @since 2.1.0 15.12.2020 13:29
     */
    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }

    /**
     * Taken as is from the example
     * @implNote 3.2.0 27.07.2021 23:30 now db based
     * @since 2.1.0 15.12.2020 13:29
     */
    handleChange = input => event => {
      console.log("The input here is ", input);
      console.log("The value here is ", event.target.value);
      //const con = this.state.xmlScores[this.state.step - 1][input];
      //console.log("We want to update ", con);
      /*
       * FINALLY! Got it to update
       * properly (2.1.0 16.12.2020 14:53)
       * thanks to mpen from
       * https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
       * for saving me here. The following is an adaptation of his answer.
       */
      // 1. Make a shallow copy of the items
      //let scores = [...this.state.xmlScores];
      let scores = [...this.state.scores];
      // 2. Make a shallow copy of the item you want to mutate
      //let score = [...scores[this.state.step - 1][input]];
      let score = scores[this.state.step - 1]["answers"][input]["score"];
      console.log("We want to update ", score);
      // 3. Replace the property you're intested in
      score = parseInt(event.target.value, 10);
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      //scores[this.state.step - 1][input] = score;
      scores[this.state.step - 1]["answers"][input]["score"] = score;
      // 5. Set the state to our new copy
        this.setState((state) => {
          return {
            //xmlScores: scores,
            scores: scores,
          }
        });
    }

    /**
     * Submit the changes to the dashboard.
     * @implNote 3.2.0 27.07.2021 23:30 now db based
     * @since 2.1.0 17.12.2020 12:03
     */
    submitChanges() {
      //this.props.submitForm(this.state.xmlScores);
      this.props.submitForm(this.state.scores);
    }

    /**
     *
     * @since 2.1.0 15.12.2020 13:29
     */
    render(){
      /*
       * First two lines -
       * pull the step and all the
       * question data from the state
       */
      const step = this.state.step;
      console.log("step is",step);
      //const {xmlTexts, xmlScores} = this.state;
      /*
       * Next three lines -
       * pull the texts, the scores and the weight
       * from the relevant array
       */
      //const currentQuestion = this.state.xmlTexts[step - 1];
      //let currentScores = this.state.xmlScores[step - 1];
      // DEBUG
      //console.log("this.state.xmlScores[step - 1] is ",this.state.xmlScores[step - 1]);
      //console.log("currentScores is ",currentScores);
      /*
       * 3.2.0 27.07.2021 22:56
       * Now we are with new db based scoring scheme
       * so the stuff is a bit different
       */
      const currentQuestionText = this.state.scores[step - 1]["questionText"];
      let currentAnswers = this.state.scores[step - 1]["answers"];
      console.log("this.state.scores[step - 1] is ",this.state.scores[step - 1]);
      console.log("currentAnswers is ",currentAnswers);
      /*
       * I added currentStepNum to try and solve the bug (got a hint that might
       * to have unique keys, so I can have 1000*currentStepNum+answer num
       * 2.1.0 17.12.2020
       * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
       * 3.1.0 28.06.2021 14:50
       * dumbest bug ever...
       * I had isLastStep={step === this.state.xmlTexts.length - 1}
       * instead of isLastStep={step === this.state.xmlTexts.length}
       * even though it is obvious I treat the steps as a 1-indexed array
       */
      return ( <EditScoresQuestionForm
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        handleChange={this.handleChange}
        cancelForm={this.props.cancelForm}
        submitForm={this.submitChanges}
        currentQuestionText={currentQuestionText}
        currentAnswers={currentAnswers}
        isFirstStep={step === 1}
        isLastStep={step === this.state.scores.length}
        currentStepNum={step}
               />
            )
    }

}

export default EditScoresMainForm;
