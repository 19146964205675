import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
import { Dropdown, Input } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import {Link} from "react-router-dom";

class DependencyForm extends Component {
  types = [
    {key:1, text:'the difference', value:'DIFF'},
    {key:2, text:'individual scores', value:'INDI'},
  ];
  relations = [
    {key:3, text:'>', value:'GR'},
    {key:4, text:'>=', value:'GE'},
    {key:5, text:'<', value:'L'},
    {key:6, text:'<=', value:'LE'},
    {key:7, text:'==', value:'EQ'},
    {key:8, text:'!=', value:'NE'},
  ];
  // 3.1.0 23.06.2021 11:29
  decisions = [
    {key:'CHECK', text:'Check', value:'CHECK'},
    {key:'GOOD', text:'Good', value:'GOOD'},
    {key:'BAD', text:'Bad', value:'BAD'},
  ];
  // 3.1.0 24.06.2021 12:26
  ind_binary = [
    {key:'SHLD', text:'Should', value:true},
    {key:'SHNT', text:'Should not', value:false},
  ];
  options = [];
  optionsCopy = [];
  constructor(props) {
    super(props);
    // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
    // 3.1.0 28.06.2021 13:28 UPDATE: Now codes is map, so we take keys
    for (const [code, val] of Object.entries(this.props.codes)) { // Moved here from a method 3.1.0 18.06.2021 <13:57
      if (val["isIncluded"]) { // 3.1.0 28.06.2021 14:54
        this.options.push(
          {key:code, text:code, value:code}
        );
      }
    }
    /*
     * 3.1.0 21.06.2021 13:04
     * Had a serious problem: using the same options variable meant that both
     * sets of options were getting a reference to the same object.
     * That meant changes to one changed the other. I needed to remedy that.
     * FIX202106211305
     * P.S. It suffices to only create one deep copy, because then the other thing
     * can change the original as much as they want. That would cut initialization
     * by a whole call to the deepCopy function.
     */
    this.optionsCopy = this.deepCopy(this.options);
    this.state = {
      dependentOptions: this.optionsCopy,
      independentOptions: this.options,
      dependentCode: "",
      independentCode: "",
      type: "DIFF", // DIFF is the default value
      relation: "GR", // GR is the default value
      threshold: 0, // 0 is the default value
      isBadSubmit: false, // 3.1.0 21.06.2021 13:42. To have the dropdowns 'error' on a bad submit (missing question codes)
      noYes: this.props.isPeerMatching ? "BAD" : "GOOD", // 3.1.0 23.06.2021 11:29 - DEFAULT like the leipzig one (unless peer matching)
      yesNo: "BAD", // 3.1.0 23.06.2021 11:29 - DEFAULT like the leipzig one
      noNo: "GOOD", // 3.1.0 23.06.2021 11:29 - DEFAULT like the leipzig one
      isIndependentBinary: true, // 3.1.0 24.06.2021 12:25 - DEFAULT like the leipzig one
    }
  }

  /*
   * 3.1.0 21.06.2021 13:05
   * FIX202106211305
   */
  deepCopy = (options) => {
    let copy = [];
    for (const option of options) { // Moved here from a method 3.1.0 18.06.2021 <13:57
      copy.push(
        {key:option.key, text:option.text, value:option.value}
      );
    }
    return copy;
  }

  /*
   * Thanks Paul S on https://stackoverflow.com/questions/42060961/react-router-v4-link-for-form
   * 3.1.0 18.06.2021 14:13
   */
  handleChange = (name, event) => {
    const target = event.target;
    console.log(target);
    //console.log(name);
    //console.log(target.innerText);
    // I know that for the dropdowns the info is in target.innerText, and for the input in target.value (works as of 18.06.2021 14:41)
    let value = "";
    if (name === "threshold") {
      value = target.value;
    } else {
      value = target.innerText;
    }
    /*
     * 3.1.0 21.06.2021 10:15 after some debugging, I realized that when
     * navigating and pressing ENTER we get the selection + the whole list.
     * That's why the following is added
     */
     if (value.includes('\n')) {
       //console.log("We had a line break, taking first line ");
       value = value.substring(0, value.indexOf('\n'));
     }
    console.log(value);
    // 3.1.0 18.06.2021 15:29 - To disable chosen codes in the other list to prevent the dependent and independent to be the same
    if (name === "dependentCode" || name === "independentCode") {
      /*
       * 3.1.0 21.06.2021 09:44 a check to see if undefined to prevent bugs.
       * thanks Anurag and reformed on https://stackoverflow.com/questions/3390396/how-can-i-check-for-undefined-in-javascript
       * (09:48) Found out the problem was different.
       * When not properly selected, value was ALL THE VALUES (one twice).
       * That's why it fails. So we need to do a check for that, so ut returns
       * immediately.
       * (09:59) separated to handle the lie break separately when I realized
       * that the first row is always the selected one
       * (10:02) Thanks to selectOnBlur={false}, no longer an issue
       */
      /*if (typeof value === 'undefined') {
        console.log("We had an undefined value");
        return;
      }
      else if (value.includes('\n')) {
        console.log("We had a line break, taking first line ");
      }*/
      /*
       * There is a bug, where disabling the first item of the other list
       * still lets you choose it the first time you open it by pressing
       * ENTER as soon as you open it (3.1.0 21.06.2021 13:17).
       * I am therefore forced to check for it, having already added a value
       * prop to (one for now) dropdown, so that if I avoid updating the state,
       * it will reset to placeholder on rerender. Therefore, I will check if
       * the selected value should be disabled, in whic case I change value to
       * "" so on setState, it will be re rendered, and also
       * calling the disableCodeInOtherList function only if this is NOT the
       * case (i.e. it is a valid selection). FIX202106211324
       */
      if (!this.checkSelectability(name, value)) {
        value = "";
      }
      else {
        this.disableCodeInOtherList(name, value);
      }
    }
    else if (name !== "threshold") { // for type&relation convert from text to value 3.1.0 21.06.2021 11:51
      value = this.extractProperValue(name, value);
    }
    /*
     * 3.1.0 23.06.2021 13:40
     * Added the code that in the case of peermatching,
     * updates yesNo and noYes together
     */
    if (this.props.isPeerMatching && (name === "yesNo" || name === "noYes")) {
      this.setState((state) => {
        return {
          yesNo: value,
          noYes: value,
          isBadSubmit: false,} // 21.06.2021 13:44, added isBadSubmit: false so that the error will go away after interacting with the form
      });
    } else {
      this.setState((state) => {
        return {[name]: value,
                isBadSubmit: false,} // 21.06.2021 13:44, added isBadSubmit: false so that the error will go away after interacting with the form
      });
    }
  }

  /*
   * 3.1.0 21.06.2021 13:24
   * FIX202106211324
   * (13:34) FIX IS WORKING!
   */
  checkSelectability = (name, code) => {
    let optionsName = "";
    // We need to check the list for current selection, as opposed to when disabling
    if (name === "dependentCode") {
      optionsName = "dependentOptions";
    } else {
      optionsName = "independentOptions";
    }
    let options = this.state[optionsName];
    // extract the selection in the other list
    let option = options.filter(element => {
      return element.value === code;
    });
    // The previous statement returns an array of objects, but I want an object
    // Here I extract the only object in the array
    option = option[0];
    console.log(option);
    /*
     * Here is the actual check:
     * if disabled exists, and is true, return false.
     * else return true.
     */
     if (option["disabled"] && option["disabled"] === true) {
       console.log("SELECTION NOT ALLOWED");
       return false;
     } else {
       return true;
     }
  }
  /*
   * Debugging showed me it took the text and not the desired value.
   * works as of 12:13
   * 3.1.0 21.06.2021 10:19
   */
  extractProperValue = (name, value) => {
    let options = [];
    switch (name) {
      case "type":
        options = this.types;
        break;
      case "relation":
        options = this.relations;
        break;
      // These 3 on 3.1.0 23.06.2021 12:56
      case "noNo":
      case "noYes":
      case "yesNo":
        options = this.decisions;
        break;
      case "isIndependentBinary":
        options = this.ind_binary;
        break;
      default:
        return value; // Only type and relation need a conversion (and the nono yesno noyes)
    }
    //console.log(options);
    //console.log(value);
    let option = options.filter(element => {
      return element.text === value;
    });
    // The previous statement returns an array of objects, but I want an object
    // Here I extract the only object in the array
    option = option[0];
    //console.log(option);
    return option.value;
  }
  /*
   * When a code is chosen in one list, disable it for now in the other
   * and re enable the previous disabled one if relevant
   * @fix 3.1.0 21.06.2021 12:15: checking for old disabled option should
   * be with this.state[name], i.e. the currently selected value of THIS
   * dropdown.
   * 3.1.0 18.06.2021 <15:45
   */
  disableCodeInOtherList = (name, code) => {
    let otherName = "";
    if (name === "dependentCode") {
      otherName = "independentOptions";
    } else {
      otherName = "dependentOptions";
    }
    let options = this.state[otherName];
    console.log("the options for " + otherName);
    console.log(options);
    console.log("Was there an old disabled option?");
    console.log(this.state[name]);
    // If there was a chosen value, you need to reenable it
    //let oldOption = this.state.dependentOptions.filter(option => {
    let oldOption = options.filter(element => {
      return element.value === this.state[name];
    });
    // The previous statement returns an array of objects, but I want an object
    // first check that there was an old disabled option
    if (oldOption.length > 0) {
      oldOption = oldOption[0]; // Here I extract the only object in the array
      console.log(oldOption);
    }
    // disable the new option in the other list
    let option = options.filter(element => {
      return element.value === code;
    });
    // The previous statement returns an array of objects, but I want an object
    // Here I extract the only object in the array
    option = option[0];
    const oldIndex = options.indexOf(oldOption);
    const index = options.indexOf(option);
    if (oldIndex > -1) {
      oldOption["disabled"] = false; // Change the disabled to false
      options.splice(oldIndex, 1); // thanks https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
      options.splice(oldIndex, 0, oldOption); // thanks tvanfosson and etoxin on https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
    }
    option["disabled"] = true; // Add disabled or change it to true
    console.log(option);
    options.splice(index, 1); // thanks https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
    options.splice(index, 0, option); // thanks tvanfosson and etoxin on https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
    this.setState((state) => {
      return {[name]: options,}
    });
  }

  /**
   * Send the data to the MatchingDashboardBackEndCommunicator
   * to be sent to the backend to handle.
   * If I ever decide to communicate directly with the
   * database for performance, I shall change the call here.
   * @implNote Adding an if else to check for a bad submit 3.1.0 21.06.2021 13:43
   * @since 3.0.0 29.04.2021 14:12
   */
  submitForm = () => {
    const { dependentCode, independentCode, type, relation, threshold,
      noYes, yesNo, noNo, isIndependentBinary} = this.state;
    if (dependentCode === "" || independentCode === "" ) {
      this.setState((state) => {
        return {isBadSubmit: true,}
      });
    } else {
      MatchingDashboardBackEndCommunicator.submitDependency(dependentCode, independentCode, type, relation, threshold,
        noYes, yesNo, noNo, isIndependentBinary, this.props.matchingName);
      this.props.getBack();
    }
  }

  /**
   *
   * @since 3.1.0 23.06.2021 12:43
   */
  prepareDecisionElements() {
    const formFields = [];
    if (this.state.type === "DIFF") {
      formFields.push(
        "If the difference does not pass this condition, it should be treated as ",
        //{' '}
        <Dropdown floating inline selectOnNavigation={false}
          selectOnBlur={false}
          value={this.state.noNo}
          onChange={this.handleChange.bind(this,"noNo")}
          options={[this.decisions[1], this.decisions[2]]} />,//{' '}
        " for the match"
      );
    } else {
      const rowTitle = this.props.isPeerMatching ? "Peer 1" : "Mentee";
      const colTitle = this.props.isPeerMatching ? "Peer 2" : "Mentor";
      // The entire table was done with the help https://react.semantic-ui.com/collections/table
      formFields.push(
      <Table definition celled structured className="tableDropdown centered"
      textAlign='center' verticalAlign='middle'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='2' rowSpan='2' collapsing/>
            <Table.HeaderCell colSpan='2' collapsing>{colTitle}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Yes</Table.HeaderCell>
            <Table.HeaderCell>No</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell rowSpan='2' collapsing>{rowTitle}</Table.Cell>
            <Table.Cell>Yes</Table.Cell>
            <Table.Cell>
              <Dropdown floating inline disabled
                placeholder={"Check"} />
            </Table.Cell>
            <Table.Cell>
              <Dropdown floating inline selectOnNavigation={false}
                selectOnBlur={false}
                value={this.state.yesNo}
                onChange={this.handleChange.bind(this,"yesNo")}
                options={this.decisions} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>No</Table.Cell>
            <Table.Cell>
              <Dropdown floating inline selectOnNavigation={false}
                selectOnBlur={false}
                value={this.state.noYes}
                onChange={this.handleChange.bind(this,"noYes")}
                options={this.decisions} />
            </Table.Cell>
            <Table.Cell>
              <Dropdown floating inline selectOnNavigation={false}
                selectOnBlur={false}
                value={this.state.noNo}
                onChange={this.handleChange.bind(this,"noNo")}
                options={[this.decisions[1], this.decisions[2]]} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
    }
    return formFields;
  }

  /* Thanks Boky on https://stackoverflow.com/questions/40989524/how-to-get-id-and-input-data-when-onchange-event-is-happening-react-js
   * for the trick with the bind in order to only have one handleChange function (3.1.0 18.06.2021 <<14:32)
   */
  render() {
    //console.log("codes: " + this.props.codes);
    console.log("dependent code: " + this.state.dependentCode);
    console.log("dependent code: " + this.state.independentCode);
    console.log("type: " + this.state.type);
    console.log("relation: " + this.state.relation);
    console.log("threshold: " + this.state.threshold);
    console.log("noYes: " + this.state.noYes);
    console.log("yesNo: " + this.state.yesNo);
    console.log("noNo: " + this.state.noNo);
    console.log("isPeerMatching: " + this.props.isPeerMatching);
    let decisionsElements = this.prepareDecisionElements();
    //this.prepareForm();
    /*
     * 3.1.0 21.06.2021 09:37
     * Because of a buggy behaviour, where opening the dropdown and
     * losing it initiated the onChange function even if the cursor was on
     * a disabled option, which caused an exception,
     * I started messing around with it.
     * I did closeOnBlur={false} but it did not help. because ot stop registered
     * as a change. I tried adding selectOnNavigation={false} but it still did not
     * work. So I decided to leave both of those and change it from onChange
     * to onClick, which hopefully will not be trigerred if someone clicks a
     * disabled option.
     * (09:41) was wrong thing. It trigerred when I tried to open the list.
     * will instead add a check to the function.
     * (09:52) changing to onClick again, because
     * (10:01) THANKS user2962393 on https://stackoverflow.com/questions/45984001/how-to-prevent-onchange-event-fire-onblur-in-semantic-ui-react/46132923#46132923
     * for the life saving selectOnBlur={false}!
     * (10:09) Let's try to allow closeOnBlur since you can't selectOnBlur (closeOnBlur={false})
     * WORKS WELL!!! WOOHOOO!!!!!!!!! I can even allow selectOnNavigation then
     * maybe? The answer is nope
     * (12:58) Using ENTER allows the selection of disabled items. To combat this,
     * I'm experimenting with putting
     * value={this.state.independentCode !== "" ? this.state.independentCode : "independent question"}
     * and checking for the same code that should be disabled.
     * This comes with a performance hit apparently (apparently this was not controlled
     * earlier). (13:32) This approach is working. Now disabled items are cleared
     * automatically back to placeholder value without being recorded in state.
     * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
     * (13:40) Add an error boolean for the first two dropdowns that will be
     * turned true if an attempt at submit was made without selecting both
     * question codes.
     */
    return(
      <Form className="ui centered">
        <div className="ui centered center-stage">
          Question {' '}
          <Dropdown floating inline selectOnNavigation={false}
            selectOnBlur={false} error={this.state.isBadSubmit} scrolling
            value={this.state.dependentCode !== "" ? this.state.dependentCode : "dependent question"}
            onChange={this.handleChange.bind(this,"dependentCode")}
            options={this.state.dependentOptions} placeholder='dependent question' />{' '}
          should depend on question {' '}
          <Dropdown floating inline selectOnNavigation={false}
            selectOnBlur={false} error={this.state.isBadSubmit} scrolling
            value={this.state.independentCode !== "" ? this.state.independentCode : "independent question"}
            onChange={this.handleChange.bind(this,"independentCode")}
            options={this.state.independentOptions} placeholder='independent question' />{' '}
          <br />
          The dependency should be calculated over {' '}
          <Dropdown floating inline selectOnNavigation={false}
            selectOnBlur={false}
            onChange={this.handleChange.bind(this,"type")}
            options={this.types} defaultValue={this.types[0].value} />{' '}
          <br />
          with the relation {' '}
          <Dropdown floating inline selectOnNavigation={false}
            selectOnBlur={false}
            onChange={this.handleChange.bind(this,"relation")}
            options={this.relations} defaultValue={this.relations[0].value} />{' '}
          <Input size='mini' defaultValue={0}
            onChange={this.handleChange.bind(this,"threshold")}/>
          <br />
          If the independent question needs to be checked, it {' '}
          <Dropdown floating inline selectOnNavigation={false}
            selectOnBlur={false}
            onChange={this.handleChange.bind(this,"isIndependentBinary")}
            options={this.ind_binary} defaultValue={this.ind_binary[0].value} />{' '}
          be checked in a binary manner<br />(if the difference is not 0, then it gets the maximal possible difference)
          <br />
          {decisionsElements}
        </div>
        <div className="buttonGroup">
          <Link className="btn btn-warning" to="/dash/edit_meta" id="backButton">
            Back
          </Link>
          <Button type="submit" className="btn btn-success" id="submitButton"
             onClick={this.submitForm}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }
}

export default DependencyForm;
