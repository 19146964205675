import 'bootstrap/dist/css/bootstrap.css';
//import Button from 'react-bootstrap/Button';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/form.css';
//import MatchingDashboardBackEndCommunicator from './MatchingDashboardBackEndCommunicator.js';
//import EditScoresMainForm from './FormComponents/EditScoresMainForm';
//import EditWeightsForm from './FormComponents/EditWeightsForm';
import LoginForm from './FormComponents/LoginForm';
import { Container } from 'semantic-ui-react';
import Dashboard from './Dashboard.js';
import MatchingMetaDashboard from './MatchingMetaDashboard.js';
import {
  BrowserRouter as Router,
  Switch,
  Route//,
  //Link,
  //Redirect
} from "react-router-dom";

class TopLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
      * A boolean to track whether to show
      * the matching meta dashboard or the
      * matching dashboard
      * 3.0.0 27.04.2021 11:22
      */
      isMeta: true,
      /**
      * This will hold the
      * current chosenMeta
      * @since 3.0.0 27.04.2021 13:52
      */
      matchingName: "",
      /**
      * This will hold the acceptable
      * matching meta names for the user
      * @since 3.0.0 27.04.2021 14:10
      */
      metaNames: [],
      /**
      *
      */
      username: "",//"tech4comp",
      /**
      * This will hold the
      * current chosenMeta isOwner
      * @since 4.0.0 10.09.2021 11:52
      */
      chosenMetaIsOwner: false,
    }
  }

  /**
  * This function toggles between
  * meta and regular dashboard
  * @implNote 4.0.0 10.09.2021 12:14 will now also accept
  * the isOwner boolean, and set it here
  * @since 3.0.0 27.04.2021 11:28
  */
  toggle = (matchingName, isOwner) => {
    this.setState((state) => {
      return {
        isMeta: !this.state.isMeta,
        matchingName: matchingName,
        chosenMetaIsOwner: isOwner,
      }});
      // console.log("index matching name: ", matchingName);
      // console.log("index matching name: ", this.state.matchingName);
      // console.log("index isOwner: ", isOwner);
      // console.log("index isOwner: ", this.state.chosenMetaIsOwner);
  }

  /**
  * To propagate change upwards
  * from the MatchingMetaDashboard in
  * order to avoid calling the backend all
  * the time
  * @since 3.0.0 27.04.2021 14:14
  */
  saveMetaNames = (metaNames) => {
    this.setState((state) => {
      return {
        metaNames: metaNames,
      }});
  }

  /**
  * To propagate login upwards
  * from the LoginForm
  * @since 3.0.0 27.04.2021 14:14
  */
  getUsernameFromLogin = (username) => {
    this.setState((state) => {
      return {
        username: username,
      }});
  }

  /**
  * To propagate logout upwards
  * from the MatchingMetaDashboard
  * and Dashboard
  * @since 3.0.0 27.04.2021 15:39
  */
  logout = () => {
    this.setState((state) => {
      return {
        username: "",
        metaNames: [],
        matchingName: "",
      }});
  }

  render() {
    console.log("index matching name: ", this.state.matchingName);
    console.log("index isOwner: ", this.state.chosenMetaIsOwner);
    /*if (this.state.isMeta) {
    return (
    <MatchingMetaDashboard
    toggle={this.toggle}
    saveMetaNames={this.saveMetaNames}
    chosenMeta={this.state.matchingName}
    metaNames={this.state.metaNames}
    username={this.state.username}
  />
  );
  }
  else {
  return (
    <Dashboard
      toggle={this.toggle}
      matchingName={this.state.matchingName}
      username={this.state.username}
    />
  );
  }*/
  /*if(this.state.username === "") {
  return(

    <Redirect to="/login" />
  );
  }
  else {*/
  return(
    <div>
      <Switch>
        <Route path="/dash" exact={false}>
          <Dashboard
            toggle={this.toggle}
            matchingName={this.state.matchingName}
            username={this.state.username}
            logout={this.logout}
            redirector={this.redirector}
            chosenMetaIsOwner={this.state.chosenMetaIsOwner}
          />
        </Route>
        <Route path="/login">
          <LoginForm
            getUsernameFromLogin={this.getUsernameFromLogin}
          />
        </Route>
        <Route path="/register"></Route>
        <Route path="/">
          <Container className="center-stage">
            <MatchingMetaDashboard
              toggle={this.toggle}
              saveMetaNames={this.saveMetaNames}
              chosenMeta={this.state.matchingName}
              metaNames={this.state.metaNames}
              username={this.state.username}
              logout={this.logout}
              chosenMetaIsOwner={this.state.chosenMetaIsOwner}
            />
          </Container>
        </Route>
      </Switch>
    </div>
  );
//}
}
}

// ========================================

ReactDOM.render(
  //ReactDOM.hydrate(
  <Router>
    <TopLevel />
  </Router>
  ,
  document.getElementById('root')
);
