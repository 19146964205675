import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'semantic-ui-react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import {Link} from "react-router-dom";

class DependencyRemovalForm extends Component {
  types = [
    {key:1, text:'the difference', value:'DIFF'},
    {key:2, text:'individual scores', value:'INDI'},
  ];
  relations = [
    {key:3, text:'>', value:'GR'},
    {key:4, text:'>=', value:'GE'},
    {key:5, text:'<', value:'L'},
    {key:6, text:'<=', value:'LE'},
    {key:7, text:'==', value:'EQ'},
    {key:8, text:'!=', value:'NE'},
  ];
  dependencies = [];
  options = [];
  constructor(props) {
    super(props);
    MatchingDashboardBackEndCommunicator.getDependencies(this.props.matchingName)
    .then(response => {
      //console.log(response);
      // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
      //for (const dependency of response.data) { // Moved here from a method 3.1.0 18.06.2021 <13:57
        this.dependencies = response.data;
      //}
      //console.log(this.dependencies);
      let index = 0;
      // Thanks Christian C. Salvadó & meager on https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
      for (const dependency of this.dependencies) {
        // 22.06.2021 10:08: get the right relation with filter, take the [0]
        // to get out of the aray(1) and then take the text element
        let relation = this.relations.filter(option => {
          return option.value === dependency["relation"];
        })[0]["text"];
        // (10:09) do the same for the type
        let type = this.types.filter(option => {
          return option.value === dependency["type"];
        })[0]["text"];
        let optionText = dependency["dependentCode"] + "--> "
          + type + " " + relation  + " " +
          + dependency["threshold"] + " -->" + dependency["independentCode"];
        //console.log(optionText);
        this.options.push(
          {key:index, text:optionText, value:index}
        );
        index = index + 1;
      }
    });

    /*
     *
     */
    this.state = {
      deletionCandidates: [],
    }
  }

  /*
   * Thanks Paul S on https://stackoverflow.com/questions/42060961/react-router-v4-link-for-form
   * @implNote on 22.06.2021 I added tghe parameter {value}, which contains the
   * values (corresponding to deoendency indices) of ALL sellected options.
   * This is excellent!
   * 3.1.0 21.06.2021 ???
   */
  handleChange = (event, {value}) => {
    //const target = event.target;
    //console.log(event);
    //console.log(target);
    //console.log(value);
    //console.log(name);
    //console.log(target.innerText);
    // I know that for the dropdowns the info is in target.innerText, and for the input in target.value (works as of 18.06.2021 14:41)
    //let data = "";
    //console.log(data);
    //data = target.innerText;
    //console.log(data);
    /*let candidateOptions = this.options.filter(option => {
      return value.includes(option.value);
    });
    console.log(candidateOptions);*/
    let candidates = this.dependencies.filter(dep => {
      return value.includes(this.dependencies.indexOf(dep));
    });
    //console.log(candidates);
    /*
     * 3.1.0 21.06.2021 10:15 after some debugging, I realized that when
     * navigating and pressing ENTER we get the selection + the whole list.
     * That's why the following is added
     */
     //if (value.includes('\n')) {
       //console.log("We had a line break, taking first line ");
      // value = value.substring(0, value.indexOf('\n'));
     //}

    /*
     *???????????????
     */
    this.setState((state) => {
      return {deletionCandidates: candidates,
              } // 21.06.2021 13:44, added isBadSubmit: false so that the error will go away after interacting with the form
    });
  }

  /**
   * Send the data to the MatchingDashboardBackEndCommunicator
   * to be sent to the backend to handle.
   * If I ever decide to communicate directly with the
   * database for performance, I shall change the call here.
   * @implNote Adding an if else to check for a bad submit 3.1.0 21.06.2021 13:43
   * @since 3.0.0 29.04.2021 14:12
   */
  submitForm = () => {
    const { deletionCandidates } = this.state;
    MatchingDashboardBackEndCommunicator.deleteDependencies(deletionCandidates, this.props.matchingName);
    this.props.getBack();
  }

  /* Thanks Boky on https://stackoverflow.com/questions/40989524/how-to-get-id-and-input-data-when-onchange-event-is-happening-react-js
   * for the trick with the bind in order to only have one handleChange function (3.1.0 18.06.2021 <<14:32)
   */
  render() {
    /*console.log("dependent code: " + this.state.dependentCode);
    console.log("dependent code: " + this.state.independentCode);
    console.log("type: " + this.state.type);
    console.log("relation: " + this.state.relation);
    console.log("threshold: " + this.state.threshold);*/
    console.log(this.state.deletionCandidates);
    //this.prepareForm();
    /*
     *
     */
    return(
      <Form className="ui centered">
        <div className="ui centered center-stage">
          Choose dependencies to be deleted {'\n'}
          <Dropdown multiple selection selectOnNavigation={false}
            selectOnBlur={false}
            onChange={(e,{value}) => this.handleChange(e,{value})}
            options={this.options} placeholder='dependencies' />{' '}
        </div>
        <div className="buttonGroup">
          <Link className="btn btn-warning" to="/dash/edit_meta" id="backButton">
            Back
          </Link>
          <Button type="submit" className="btn btn-danger" id="submitButton"
             onClick={this.submitForm}>
            Delete Selected Dependencies
          </Button>
        </div>
      </Form>
    );
  }
}

export default DependencyRemovalForm;
