import React, { Component } from 'react';
import '../css/form.css';
import MatchingDashboardBackEndCommunicator from '../MatchingDashboardBackEndCommunicator.js';
import { Form, Button } from 'semantic-ui-react';
import { //Link,
  //Redirect, 
  withRouter//, useHistory
  } from "react-router-dom";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
      * The name of the logged
      * in user.
      * It will be hardcoded until I
      * implement the auth/sso stuff
      * 3.0.0 27.04.2021 11:06
      */
      username: this.props.username,//"tech4comp",

    }
    this.prepareForm = this.prepareForm.bind(this);
    //this.handleNameChange = this.handleNameChange.bind(this);
  }

  /**
  * Update value
  * @since 3.0.0 28.05.2021 10:20
  */
  handleChange = event => {
    this.setState((state) => {
      return {
        username: event.target.value,
      }});
    }

    /**
    *
    * @since 3.0.0 28.05.2021 10:41
    */
    prepareForm() {
      const formFields = [];
      //      for (var i = 1; i < currentQuestion.length; i++) {
      formFields.push(
        <Form.Field key={200000} required

        >
          <label>Username</label>
          <Form.Input fluid icon='user' iconPosition='left'
            //placeholder='Score'
            name="username"
            defaultValue=""
            onChange={this.handleChange}
        />
        </Form.Field>
      );
      formFields.push(
        <Form.Field key={200001} required
          //onChange={this.handleTypeChange}
        >
          <label>Enter Password</label>
          <Form.Input fluid
            name="password"
            icon='lock'
            iconPosition='left'
          placeholder='Password' type='password' />
        </Form.Field>
      );
      return(formFields);
    }

    /**
    * Send the data to the MatchingDashboardBackEndCommunicator
    * to be sent to the backend to handle.
    * If I ever decide to communicate directly with the
    * database for performance, I shall change the call here.
    * @since 3.0.0 29.04.2021 14:12
    */
    submitForm = (event) => {
      MatchingDashboardBackEndCommunicator.login(event.target.username.value,
        event.target.password.value)
        .then(
          response => {
            console.log(response.data.result);
            if (response.data.result === "success") { // If login is successful, update the dashboard's username
              this.props.getUsernameFromLogin(this.state.username);
              //<Redirect to="/" />
              this.props.history.push("/");
            }
          }
        );
    }

    /**
    *
    * @implNote the back button Toggles the other state without saving info
    * @since 2.1.0 15.12.2020 13:37
    */
    render(){

      let formFields = this.prepareForm();
      return(
        <Form onSubmit={this.submitForm}>
          <h1 className="ui centered">Login:</h1>
          {formFields}
          {/*<Button onClick={() => this.props.toggleCreation()}>
            Cancel
          </Button>*/}
          <Button type="submit">{/* as={Link} to='/'>{/*onClick={this.submitForm}>*/}
            {/*<Link type="submit" to='/'>*/}{/* as={Link} to='/'>{/*onClick={this.submitForm}>*/}
            Login
            {/*</Link>*/}
          </Button>
        </Form>
      )
    }

  }


  export default withRouter(LoginForm); // Thanks for the withRouter tip 3.0.0 28.05.2021 https://stackoverflow.com/questions/42672842/how-to-get-history-on-react-router-v4 Karl Taylor
