/**
 * The Component to handle the score
 * editing for a specific question
 * @api note - Adapted from the example
 * in https://www.digitalocean.com/community/tutorials/how-to-create-multistep-forms-with-react-and-semantic-ui
 * @since 2.1.0 15.12.2020 ~13:34
 */
 import React, { Component } from 'react';
 import { Form } from 'semantic-ui-react';
 import '../css/form.css';
 //import { Button } from 'semantic-ui-react';
 import Button from 'react-bootstrap/Button';
 class EditScoresQuestionForm extends Component {

   /**
    * Taken as is from the example
    * @since 2.1.0 15.12.2020 13:37
    */
   saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    /**
     * Taken as is from the example
     * @since 2.1.0 15.12.2020 14:50
     */
    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    /**
      *
      * @since 2.1.0 15.12.2020 13:57
      * @since 3.2.0 27.07.2021 23:01 got revamped for db based scoring scheme
      */
    //pullAnswersTextAndScores(currentQuestion, currentScores) {
    pullAnswersTextAndScores(currentAnswers) {
      const formFields = [];
      for (var i = 0; i < currentAnswers.length; i++) { // Starts at 0 not 1 as of 3.2.0 27.07.2021 23:03
        /*
         * Here we push a Form.field
         * to the array.
         * The label is currentQuestion[i]
         * (the answer's text),
         * and the default value is the
         * current score for this answer
         * (currentScores[i - 1] because it
         * has one element less than currentQuestion)
         * 2.1.0 17.12.2020 ~10:37 UPDATE -
         * Added + (1000 * this.props.currentStepNum)
         * 10:40 - HOLY SHIT IT ACTUALLY WORKS!!! THANKS
         * Sia from https://stackoverflow.com/questions/30727837/react-change-input-defaultvalue-by-passing-props
         * (this fixed the bug where the wrong values were sometimes shown.
         * I'm guessing that the non unique keys made it so that default values
         * were shown according to the keys and they were not unique so some
         * default values from previous fields were showing. And since apparently
         * the default values are done once that screwed things up).
         * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
         * 3.2.0 27.07.2021 23:03
         * Now with changes for new db based scoring scheme
         */
        //console.log("currentScores[i-1] inside the form fields builder is ",currentScores[i-1]);
        console.log("currentAnswers[i] inside the form fields builder is ",currentAnswers[i]);
        /*formFields.push(
          <Form.Field key={i + (1000 * this.props.currentStepNum)}>
            <label>{currentQuestion[i]}</label>
            <input
              //placeholder='Score'
              //onChange={this.props.handleChange('currentScores[' + (i - 1) + ']')}
              defaultValue={currentScores[i - 1]}
              onChange={this.props.handleChange(i - 1)}

                    />
          </Form.Field>
        );*/
        formFields.push(
          <Form.Field key={i + (1000 * this.props.currentStepNum)}>
            <label>{currentAnswers[i]["answerText"]}</label>
            <input
              defaultValue={currentAnswers[i]["score"]}
              onChange={this.props.handleChange(i)}
                    />
          </Form.Field>
        );

      }
      return(formFields);
    }

    /**
     * if step is 1, then the back button
     * will be the cancel one.
     * Otherwise, it just goes back one step.
     * @since 2.1.0 15.12.2020 14:36
     */
    decideOnBackButton(isFirstStep) {
        if (isFirstStep) {
          return (
            <Button className="btn-warning" id="backButton"
              onClick={this.props.cancelForm}>
              Back to dashboard (undo changes)
            </Button>
          )
        }
        else {
          return (
            <Button onClick={this.back} id="backButton">
              Back
            </Button>
          )
        }
    }

    /**
     * if step is 1, then the back button
     * will be the cancel one.
     * Otherwise, it just goes back one step.
     * @since 2.1.0 15.12.2020 14:43
     */
    decideOnNextButton = isLastStep => {
        if (isLastStep) {
          console.log("This is the last step");
          return (
            <Button type="submit" className="btn-success" id="submitButton"
              onClick={this.props.submitForm}>
              Submit scores
            </Button>
            /*
             * Trying to fix the Form submission problem solved
             * 2.1.0 16.12.2020 13:56
             */
            /* <Form.Button content='Submit' onClick={this.props.submitForm('xmlScores')}>
               Submit scores
             </Form.Button>*/
          )
        }
        else {
          return (
            <Button onClick={this.saveAndContinue} id="submitButton">
              Next
            </Button>
          )
        }
    }

    /**
     * 
     * @since 2.1.0 15.12.2020 13:37
     */
     render(){
       //const {currentQuestion, currentScores} = this.props;
       //console.log("currentScores[1] inside the question form is ",currentScores[1]);
       //const questionText = currentQuestion[0];
       const {currentQuestionText, currentAnswers} = this.props;
       //let formFields = this.pullAnswersTextAndScores(currentQuestion, currentScores);
       let formFields = this.pullAnswersTextAndScores(currentAnswers);
       const backButton = this.decideOnBackButton(this.props.isFirstStep);
       const nextButton = this.decideOnNextButton(this.props.isLastStep);
       return(
         <Form >
           <h1 className="ui centered">{currentQuestionText}</h1>
           {formFields}
           <div className="buttonGroup">
             {backButton}
             {nextButton}
           </div>
         </Form>
       )
     }

 }

 export default EditScoresQuestionForm;
